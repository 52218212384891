<template>
  <div class="training-main">
    <div class="train-glass"></div>
    <!-- intro -->
    <div class="training-block js-training-intro training-first">
      <div @click="closeTrain()" class="btn-closeTrain">
        <span class="closeTrain-a"></span>
      </div>
      <div class="icon-train icon-train--mobile">
        <img src="images/interface/head.png" alt="">
      </div>
      <p class="training-h1 js-training--h1 js-h1--intro">
        {{$t('interfaceTraining.h1-intro-1')}} <br> {{$t('interfaceTraining.h1-intro-2')}}<strong>{{$t('interfaceTraining.h1-intro-3')}}</strong>
      </p>
      <div class="btn-training btn-training--prev">
      </div>
      <div class="btn-training btn-training--next">
        <button @click="playTraining(1)" class="training-btn js-training--btn js-btn--intro" >
          <img src="images/interface/slider/next_button.png" alt="">
        </button>
      </div>
      <div class="train-dial"></div>
    </div>
    <!-- /intro -->


    <!-- intro-2 -->
    <div class="training-block js-training-intro-2 training-first">
      <div @click="closeTrain()" class="btn-closeTrain">
        <span class="closeTrain-a"></span>
      </div>
      <div class="icon-train icon-train--mobile">
        <img src="images/interface/head.png" alt="">
      </div>
      <p class="training-h1 js-training--h1 js-h1--intro-2">
        {{$t('interfaceTraining.h1-intro2')}}
      </p>
      <div class="btn-training btn-training--prev">
      </div>
      <div class="btn-training btn-training--prev">
        <button @click="playTraining(-1)" class="training-btn js-training--btn js-btn--nav" >
          <img src="images/interface/slider/pre_button.png" alt="">
        </button>
      </div>
      <div class="btn-training btn-training--next">
        <button @click="playTraining(1);playSvg(0, -1)" class="training-btn js-training--btn js-btn--intro-2" >
          <img src="images/interface/slider/next_button.png" alt="">
        </button>
      </div>
      <div class="train-dial"></div>
    </div>
    <!-- /intro -->

    <!-- nav -->
    <div class="training-block js-training-nav training-first">
      <div @click="closeTrain()" class="btn-closeTrain">
        <span class="closeTrain-a"></span>
      </div>
      <div class="icon-train icon-train--mobile">
        <img src="images/interface/head.png" alt="">
      </div>
      <p class="training-h1 js-training--h1 js-h1--nav">
        {{$t('interfaceTraining.h1-nav-1')}}
      </p>
      <p class="training-h1 js-training--h1 js-h1--nav-mob">
        {{$t('interfaceTraining.h1-nav-1-mob')}}
      </p>
      <div id="svgTrain-0" class="svgTrain"></div>
      <div class="btn-training btn-training--prev">
        <button @click="playTraining(-1);playSvg(-1, 0)" class="training-btn js-training--btn js-btn--nav" >
          <img src="images/interface/slider/pre_button.png" alt="">
        </button>
      </div>
      <div class="btn-training btn-training--next">
        <button @click="playTraining(1);playSvg(1, 0)" class="training-btn js-training--btn js-btn--nav" >
          <img src="images/interface/slider/next_button.png" alt="">
        </button>
      </div>
      <div class="train-dial"></div>
    </div>
    <!-- /nav -->

    <!-- star-objects -->
    <div class="training-block js-training-star training-first">
      <div @click="closeTrain()" class="btn-closeTrain">
        <span class="closeTrain-a"></span>
      </div>
      <div class="icon-train icon-train--mobile">
        <img src="images/interface/head.png" alt="">
      </div>
      <p class="training-h1 js-training--h1 js-h1--star">
        {{$t('interfaceTraining.h1-star-1')}}
      </p>
      <div id="svgTrain-1" class="svgTrain"></div>
      <div class="btn-training btn-training--prev">
        <button @click="playTraining(-1);playSvg(0, 1)" class="training-btn js-training--btn js-btn--star" >
          <img src="images/interface/slider/pre_button.png" alt="">
        </button>
      </div>
      <div class="btn-training btn-training--next">
        <button @click="playTraining(1);playSvg(2, 1)" class="training-btn js-training--btn js-btn--star" >
          <img src="images/interface/slider/next_button.png" alt="">
        </button>
      </div>
      <div class="train-dial"></div>
    </div>
    <!-- /star-objects -->

    <!-- vote -->
    <div class="training-block js-training-vote training-first">
      <div @click="closeTrain()" class="btn-closeTrain">
        <span class="closeTrain-a"></span>
      </div>
      <div class="icon-train icon-train--mobile">
        <img src="images/interface/head.png" alt="">
      </div>
      <p class="training-h1 js-training--h1 js-h1--vote">
        {{$t('interfaceTraining.h1-vote-1')}}<span class="train-text--img"><img src="images/icon-train.png" alt=""></span>{{$t('interfaceTraining.h1-vote-2')}}
      </p>
      <div id="svgTrain-2" class="svgTrain">
      </div>
      <div class="btn-training btn-training--prev">
        <button @click="playTraining(-1);playSvg(1, 2)" class="training-btn js-training--btn js-btn--vote" >
          <img src="images/interface/slider/pre_button.png" alt="">
        </button>
      </div>
      <div class="btn-training btn-training--next">
        <button @click="playTraining(1);playSvg(3, 2)" class="training-btn js-training--btn js-btn--vote" >
          <img src="images/interface/slider/next_button.png" alt="">
        </button>
      </div>
      <div class="train-dial"></div>
    </div>
    <!-- /vote -->

    <!-- map -->
    <div class="training-block js-training-map training-first">
      <div @click="closeTrain()" class="btn-closeTrain">
        <span class="closeTrain-a"></span>
      </div>
      <div class="icon-train icon-train--mobile">
        <img src="images/interface/head.png" alt="">
      </div>
      <p class="training-h1 js-training--h1 js-h1--map">
        {{$t('interfaceTraining.h1-map-1')}}
      </p>
      <div id="svgTrain-3" class="svgTrain"></div>
      <div class="btn-training btn-training--prev">
        <button @click="playTraining(-1);playSvg(2, 3)" class="training-btn js-training--btn js-btn--map" >
          <img src="images/interface/slider/pre_button.png" alt="">
        </button>
      </div>
      <div class="btn-training btn-training--next">
        <button @click="playTraining(1);playSvg(4, 3)" class="training-btn js-training--btn js-btn--map" >
          <img src="images/interface/slider/next_button.png" alt="">
        </button>
      </div>
      <div class="train-dial"></div>
    </div>
    <!-- map -->

    <!-- catalog -->
    <div class="training-block js-training-catalog training-first">
      <div @click="closeTrain()" class="btn-closeTrain">
        <span class="closeTrain-a"></span>
      </div>
      <div class="icon-train icon-train--mobile">
        <img src="images/interface/head.png" alt="">
      </div>
      <p class="training-h1 js-training--h1 js-h1--catalog">
        {{$t('interfaceTraining.h1-catalog-1')}}
      </p>
      <div id="svgTrain-4" class="svgTrain"></div>
      <div class="btn-training btn-training--prev">
        <button @click="playTraining(-1);playSvg(3, 4)" class="training-btn js-training--btn js-btn--catalog" >
          <img src="images/interface/slider/pre_button.png" alt="">
        </button>
      </div>
      <div class="btn-training btn-training--next">
        <button @click="playTraining(1);playSvg(5, 4)" class="training-btn js-training--btn js-btn--catalog" >
          <img src="images/interface/slider/next_button.png" alt="">
        </button>
      </div>
      <div class="train-dial"></div>
    </div>
    <!-- /catalog -->

    <!-- chat -->
    <div class="training-block js-training-chat training-first">
      <div @click="closeTrain()" class="btn-closeTrain">
        <span class="closeTrain-a"></span>
      </div>
      <div class="icon-train icon-train--mobile">
        <img src="images/interface/head.png" alt="">
      </div>
      <p class="training-h1 js-training--h1 js-h1--chat">
        {{$t('interfaceTraining.h1-chat-1')}}
      </p>
      <div id="svgTrain-5" class="svgTrain"></div>
      <div class="btn-training btn-training--prev">
        <button @click="playTraining(-1);playSvg(4, 5)" class="training-btn js-training--btn js-btn--chat" >
          <img src="images/interface/slider/pre_button.png" alt="">
        </button>
      </div>
      <div class="btn-training btn-training--next">
        <button @click="playTraining(1);playSvg(6, 5)" class="training-btn js-training--btn js-btn--chat" >
          <img src="images/interface/slider/next_button.png" alt="">
        </button>
      </div>
      <div class="train-dial"></div>
    </div>
    <!-- /chat -->

    <!-- chat2 -->
    <div class="training-block js-training-chat2 training-first">
      <div @click="closeTrain()" class="btn-closeTrain">
        <span class="closeTrain-a"></span>
      </div>
      <div class="icon-train icon-train--mobile">
        <img src="images/interface/head.png" alt="">
      </div>
      <p class="training-h1 js-training--h1 js-h1--chat2">
        {{$t('interfaceTraining.h1-chat2-1')}}
      </p>
      <div id="svgTrain-6" class="svgTrain"></div>
      <div class="btn-training btn-training--prev">
        <button @click="playTraining(-1);playSvg(5, 6)" class="training-btn js-training--btn js-btn--chat2" >
          <img src="images/interface/slider/pre_button.png" alt="">
        </button>
      </div>
      <div class="btn-training btn-training--next">
        <button @click="playTraining(1);playSvg(8, 7)" class="training-btn js-training--btn js-btn--chat2" >
          <img src="images/interface/slider/next_button.png" alt="">
        </button>
      </div>
      <div class="train-dial"></div>
    </div>
    <!-- /chat2 -->
  </div>
</template>

<script>
import * as lottie from '@/3d/lottie';
export default {
  name: "ExpoTraining",
  mounted() {
    window.soundTrain = {};
    window.localTrain = {};
    localTrain.y = 0;
    localTrain.j = 0
    localTrain.nameBtn = ["intro","intro-2","nav","star","vote","map","catalog","chat","chat2"];
    localTrain.nameBtn2 = ["intro","intro-2", "nav","star","vote","map","catalog","chat","chat2"];
    window.isMobileOrTablet = function() {
      var check = false;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        check = true;
      }
      if(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1){
        check = true;
      }
      return check;
    }
    // "shop","sound","stream","help"
  },
  methods: {
    playTraining: function (y){
      this.clickSound();
      if(localTrain.y < localTrain.nameBtn.length){
        if(isMobileOrTablet()){
          document.querySelector(".js-h1--nav-mob").classList.add('block')
          document.querySelector(".js-h1--nav").classList.add('none')  
        }else{
          if(document.querySelector(".js-h1--nav-mob.block") != null){
            document.querySelector(".js-h1--nav-mob").classList.remove('block')
            document.querySelector(".js-h1--nav").classList.remove('none')  
          }
        }
        document.querySelector(".js-training-"+localTrain.nameBtn[localTrain.y]).classList.toggle("active");

        localTrain.y += y;
        if(localTrain.y === 9 ){
            document.querySelector(".glass-plane").classList.remove("active");
            document.querySelector(".right-item--train").classList.remove("active");
            document.querySelector(".training-main").classList.remove("active");
            document.querySelector(".point-detected").classList.remove("disable");
            document.querySelector(".nav-icon3").classList.remove("disable");
            document.querySelector(".right-bar").classList.remove('train');
            document.querySelectorAll(".right-item").forEach((item)=>{
              item.classList.remove('train');
            })
            setTimeout(()=>{
              document.querySelector(".right-item--train").remove();
              let trainBlock = document.querySelectorAll(".training-main");
              trainBlock.forEach((item)=>{
                item.remove()
              })
            },500)
            document.querySelector(".icon-train").classList.add("none");
            document.querySelectorAll(".training-first").forEach((item)=>{
                item.classList.add("none")
            })
        };
      }  
      localTrain.strel = document.querySelectorAll('.js-training-'+localTrain.nameBtn[localTrain.y])
      setTimeout(() => 
        localTrain.strel.forEach((item)=>{
          item.classList.add('active')
        })
      , 650); 
      const scripts = [
        "/js/3d/interface.js",
      ];
      scripts.forEach(script => {
        if(!document.querySelector('[src="'+ script +'"]')){
          let tag = document.createElement("script");
          tag.setAttribute("src", script);
          document.head.appendChild(tag);
        }
      })
    },
    closeTrain(){
      this.clickSound();
      document.querySelector(".glass-plane").classList.remove("active");
      document.querySelector(".point-detected").classList.remove("disable");
      document.querySelector(".right-item--train").classList.remove("active");
      document.querySelector(".training-main").classList.remove("active");
      document.querySelector(".nav-icon3").classList.remove("disable");
      document.querySelector(".right-bar").classList.remove('train');
      document.querySelectorAll(".right-item").forEach((item)=>{
        item.classList.remove('train');
      })
      setTimeout(()=>{
        document.querySelector(".right-item--train").remove();
        let trainBlock = document.querySelectorAll(".training-main");
        trainBlock.forEach((item)=>{
          item.remove()
        })
      },500)
      document.querySelector(".icon-train").classList.add("none");
      document.querySelectorAll(".training-first").forEach((item)=>{  
          item.classList.add("none")
      })
    },      
    playSvg(next,prev){
      window.lottieTrain = {}
      lottieTrain.lottie = lottie 
      localTrain.drag = document.createElement("div");
      if(next >= 0 ){
        if(next === 0 && isMobileOrTablet()){
          localTrain.drag.setAttribute("id","svgDrag--"+ next);
          localTrain.drag.setAttribute("class","svgDrag--"+ next);
          document.getElementById("svgTrain-" + next).appendChild(localTrain.drag);
          lottieTrain.lottie.loadAnimation({
            container: document.getElementById('svgDrag--0'),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/json/train/help_00-joistik.json'
          });
        }
        if(next === 0 && !(isMobileOrTablet())){
          localTrain.drag.setAttribute("id","svgDrag--"+ next);
          localTrain.drag.setAttribute("class","svgDrag--"+ next);
          document.getElementById("svgTrain-" + next).appendChild(localTrain.drag);
          lottieTrain.lottie.loadAnimation({
            container: document.getElementById('svgDrag--'+ next),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/json/train/help_0'+ next +'.json'
          });
        }
        if(next > 0 && next < 8 ){
          localTrain.drag.setAttribute("id","svgDrag--"+ next);
          localTrain.drag.setAttribute("class","svgDrag--"+ next);
          document.getElementById("svgTrain-" + next).appendChild(localTrain.drag);
          lottieTrain.lottie.loadAnimation({
            container: document.getElementById('svgDrag--'+ next),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/json/train/help_0'+ next +'.json'
          });
        }
        if(prev >= 0 && prev < 7){
          setTimeout(()=>{
            document.getElementById('svgDrag--' + prev).remove()
          }, 250)
        }
        if(prev === 7){
          document.querySelectorAll('.svgTrain').forEach((item)=>{
            item.remove();
          })
        }
      }else{
        if(document.getElementById('svgDrag--0') != null){
          setTimeout(()=>{
            document.getElementById('svgDrag--0').remove()
          }, 250)
        }
      }
    },
    clickSound(){
      let soundClick = document.querySelector('.sound-click')
      soundClick.currentTime = 0
      soundClick.play()
    },
  }
}
</script>

<style scoped>
  .train-glass{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50px;
    z-index: 100002;
  }
  .train-text--img{
    width: 25px;
    height: 25px;
    display: inline-flex;
    position: relative;
    justify-content: center;
    align-items: center;
  }
  .train-text--img img{
    position: absolute;
    top: 5px;
  }
  .js-h1--intro{
    padding-bottom: 45px;
  }
  .svgTrain{
    padding-bottom: 50px;
  }
  .btn-closeTrain{
    width: 25px;
    height: 25px;
    position: absolute;
    top: 10px;
    right: 14px;
    cursor: pointer;
  }
  .closeTrain-a{
    top: 11px;
    position: absolute;
    transform: rotate(45deg);
    width: 25px;
    height: 2px;
    background-color: #C4C4C4;
  }
  .closeTrain-a::after{
    content: "";
    position: absolute;
    transform: rotate(90deg);
    width: 25px;
    height: 2px;
    background-color: #C4C4C4;
  }
  .js-h1--nav{
    display: block;
  }
  .js-h1--nav.none{
    display: none;
  }
  .js-h1--nav-mob{
    display: none;
  }
  .js-h1--nav-mob.block{
    display: block;
  }
  @media(max-width: 767px){
    .svgTrain{
      padding-bottom: 40px;
    }
  }
</style>

