/* eslint-disable no-unused-vars */
import store from "@/store";
import * as BABYLON from 'babylonjs';
import * as ammo from "@/3d/ammo.js";
import {AmmoJSPlugin} from 'babylonjs';
import 'babylonjs-loaders';
import * as lottie from '@/3d/lottie';
import * as Walkpoints from '@/3d/walkpoints';
import * as linkArr from '@/3d/mcLinks';
import * as GUI from 'babylonjs-gui';
BABYLON.GUI = GUI;
import {initGorodki} from '@/3d/gorodkiGame';
import {initWaste} from '@/3d/wasteGameInit';
import {initFootball} from '@/3d/footballGame';
import {initSamokat} from '@/3d/samokat';
import {statBotPos} from '@/3d/statBotPos';
import {Allstars,AllBtns} from '@/3d/iconPoints';
import {gsap} from "gsap";
import {Draggable} from "gsap/Draggable";
//let store = window.vexpo.store;
async function init3d() {
  window.local = {};
  local.sBotsPoints = statBotPos;
  local.loadProgress = 0;
  //тут можно найти методы vue  local.vexpo
  await checkBot();
  function checkBot(){
      return new Promise(function(resolve, reject) {
      let checkInt = setInterval(()=>{
          if(incheck()){
            clearInterval(checkInt);
            if(store.state.userProfile!=null){
              if (store.state.userProfile.avatar != undefined ) {
                document.querySelector('.selectHero').remove();
              }
            }else{
              if(localStorage.getItem("avatar") != null ){
                document.querySelector('.selectHero').remove();
              }
            }
            resolve(true);
          }
        },100)
      });
      function incheck(){
        if(store.state.userProfile!=null || store.state.user.isAnonymous){
          return true
        }else{
          return false
        }
      }
  }

  window.isMobileOrTablet = function() {
    var check = false;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
      check = true;
    }
    if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
      check = true;
    }
    return check;
  }
  //preloader INIT
  // var preloader = lottie.loadAnimation({
  //   container: document.getElementById('load-animation'),
  //   renderer: 'svg',
  //   loop: true,
  //   autoplay: true,
  //   path: '/json/preloader.json'
  // });

  var loadedPercent = 0;
  var curWidth = document.querySelector('body').clientWidth;
  var clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  function randomFloat(min, max) {
    return Math.floor(min + (max - min) * Math.random());
  }

  let progress = setInterval(() => {
    local.loadProgress = local.loadProgress > 99 ? 100 : local.loadProgress;
    if (document.querySelector(".js-progressLoad") && local.loadProgress < 100) {
      document.querySelector(".js-progressLoad").innerHTML = local.loadProgress;
    } else {
      clearInterval(progress);
    }
  }, 50);
  local.soundStep = document.querySelector('.sound-move')
  local.soundWindow = document.querySelector('.sound-window')
  local.soundClick = document.querySelector('.sound-click')
  local.soundHover = document.querySelector('.sound-hover')
  local.soundStar = document.querySelector('.sound-star')
  local.soundCast = document.querySelector('.sound-cast')
  local.soundWin = document.querySelector('.sound-win')
  local.soundKick = document.querySelector('.sound-kick')
  local.soundFail = document.querySelector('.sound-fail')
  local.soundAlert = document.querySelector(".sound-alert")
  local.soundHover.volume = 0.1;
  local.soundClick.volume = 0.1;
  local.iSayStay = false;
  local.BOTS = [];
  local.inGame = false;
  local.videos = ["VideoTex"];
  local.sceneIsLoad = false;
  local.hero = null;
  local.botsReady = false;
  local.move = '';
  local.blend = 0;
  local.baseSpeed = 20;
  local.endPosition = '';
  local.curSpeed = 0;
  local.minSpeed = .02;
  local.RSpeed = .06;
  local.WSpeed = .06;
  local.idleR = 1;
  local.walkR = 4;
  local.canMove = true;
  local.prevChekPos = 0;
  local.appendMeshes = 0;
  local.Games = {};
  local.icons = {};
  local.meshList = ["alphaObjects.glb", "info_icon_V2.glb", "Star.glb", "upisine.glb", "FB_Arrow.glb", "PickUP.glb","Scooter.glb","dog.glb"];
  if (isMobileOrTablet()) {
    local.meshList = ["alphaObjects.glb", "info_icon.glb", "Star.glb", "upisine.glb", "FB_Arrow.glb", "PickUP.glb","Scooter.glb"];
  }
  local.observals = {};
  local.groundsNames = ["cGround"];
  local.collisionsNames = ["cWall"];
  local.InteractiveNames = ["ML_"];
  local.mapBtns = [];
  local.objLinks = [];
  local.intBtns = [];
  local.intStars = [];
  local.canRun = false;
  local.curCamPos = isMobileOrTablet() ? new BABYLON.Vector3(0.7920164902548962, 2.3950529272102563, 8.639237892026467) : new BABYLON.Vector3(0.4345016354820875, 2.3728576209754415, 5.493701396994759);
  local.StatBOTS = 0;
  local.WalkBOTS = 0;
  local.wTrueBots = [];
  local.wTruePoints = [];
  local.wTrueBots = [];
  local.sTruePoints = [];
  local.allLoad = false;
  local.AllStars = Allstars;
  local.AllBtns = AllBtns;
  local.MapPositions = {
    ML_5: new BABYLON.Vector3(-116.933, 10.806, 59.905),
    ML_5_R: new BABYLON.Vector3(0, 0, 0),
    ML_3: new BABYLON.Vector3(-100.587, 0, 10.099),
    ML_3_R: new BABYLON.Vector3(0, 0, 0),
    ML_2: new BABYLON.Vector3(-10.553, 7.557, -17.878),
    ML_2_R: new BABYLON.Vector3(0, 0, 0),
    ML_1: new BABYLON.Vector3(87.959, -5.581, 111.307),
    ML_1_R: new BABYLON.Vector3(0, 0, 0),
    ML_4: new BABYLON.Vector3(70.578, -0.056, 33.285),
    ML_4_R: new BABYLON.Vector3(0, 0, 0),
    ML_6: new BABYLON.Vector3(37.408, 0, -70.631),
    ML_6_R: new BABYLON.Vector3(0, 0, 0),
    ML_7: new BABYLON.Vector3(115.579, 0.738, 1.544),
    ML_7_R: new BABYLON.Vector3(0, 0, 0),
    ML_8: new BABYLON.Vector3(-20.01, -5.581, 122.024),
    ML_8_R: new BABYLON.Vector3(0, 0.785, 0),
    ML_9: new BABYLON.Vector3(92.409, 0.929, -27.242),
    ML_9_R: new BABYLON.Vector3(0, 0, 0),
    ML_10: new BABYLON.Vector3(-66.157, 2.4, 39.12),
    ML_10_R: new BABYLON.Vector3(0, -2.103, 0),
  };

  function cleanStars() {
    let startDone = store.state.userProfile.stars;
    if (startDone == null) {
      return
    }
    //startDone = startDone.split(",");
    startDone.forEach((item, i) => {
      let index = local.AllStars.findIndex(star => star.name == item);
      if (index > -1) {
        local.AllStars.splice(index, 1)
      }
    });
  };
  if(!store.state.user.isAnonymous)
  {
    cleanStars();
    local.starsCount = store.state.userProfile.balance;
    counterStar(0);
  }
  local.grounds = [];
  local.onMove = true;
  window.canvas = document.getElementById("renderCanvas");
  window.engine = null;
  window.scene = null;
  window.sceneToRender = null;
  let createDefaultEngine = function() {
    return new BABYLON.Engine(canvas, true, {
      preserveDrawingBuffer: true,
      stencil: true,
      disableWebGL2Support: true
    });
  };
  let createScene = function() {
    // Scene and Camera
    engine.enableOfflineSupport = false;
    let scene = new BABYLON.Scene(engine);
    async function addPhysycs() {
      const Ammo = await ammo();
      scene.enablePhysics(new BABYLON.Vector3(0, -9.81, 0), new AmmoJSPlugin(true, Ammo));
      scene.getPhysicsEngine().setSubTimeStep(10);
      //scene.createOrUpdateSelectionOctree();//optimize
    }
    addPhysycs();
    local.heroCamera = new BABYLON.ArcRotateCamera("heroCamera", Math.PI / 2, Math.PI / 4, 10, new BABYLON.Vector3(0, -5, 0), scene);
    scene.activeCamera = local.heroCamera;
    local.heroCamera.inputs.attached.pointers.panningSensibility = 0;
    scene.collisionsEnabled = true;
    local.heroCamera.target = new BABYLON.Vector3(0, 0, 0);
    local.heroCamera.setPosition(new BABYLON.Vector3(local.curCamPos.x, local.curCamPos.y, local.curCamPos.z));

    local.heroCamera.lowerBetaLimit = local.heroCamera.beta;
    local.heroCamera.upperBetaLimit = local.heroCamera.beta;

    window.lighting = BABYLON.CubeTexture.CreateFromPrefilteredData("https://assets.babylonjs.com/environments/environmentSpecular.env", scene);
    lighting.name = "runyonCanyon";
    lighting.gammaSpace = false;
    scene.environmentTexture = lighting;
    scene.createDefaultSkybox(scene.environmentTexture, true, (scene.activeCamera.maxZ - scene.activeCamera.minZ) / 2, 0.3, false);
    scene.getMeshByName("hdrSkyBox").visibility = 0;
    scene.clearColor = new BABYLON.Color3(206 / 255, 220 / 255, 220 / 255);
    // Keyboard events
    let scenePath ="Scene_V17_PC_Total.glb";// "Scene_V17_PC_Total.glb";
    if (isMobileOrTablet()) {
      scenePath = "Scene_V17_Tab_Total.glb";
      if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 || navigator.platform === 'iPhone') {
        scenePath = "Scene_V17_Tab_Total.glb";
      }
    }
    BABYLON.SceneLoader.ShowLoadingScreen = false;
    let loadDet = navigator.platform === 'iPhone' ? 2.23 : 3.3;
    BABYLON.SceneLoader.AppendAsync("/scene/", scenePath, scene, function(evt) {
      if (parseInt((parseInt(evt.loaded) * 100 / evt.total) / loadDet) > 5) {
        local.loadProgress = parseInt((parseInt(evt.loaded) * 100 / evt.total) / loadDet);
      }
      if (loadedPercent > 99) {
        local.loadProgress = navigator.platform === 'iPhone' ? parseInt(100 / 2.23) : parseInt(100 / 3.3);
      }
    }).then(function() {
      asyncAppendMeshes();

    });
    return scene;
  }
  window.initFunction = async function() {
    var asyncEngineCreation = async function() {
      try {
        return createDefaultEngine();
      } catch (e) {
        return createDefaultEngine();
      }
    }
    engine = await asyncEngineCreation();
    if (!engine) throw 'engine should not be null.';
    scene = createScene();
  };

  function startInitScene() {
    if (window.heroesIsLoaded == true) {
      initFunction().then(() => {
        sceneToRender = scene;
        engine.runRenderLoop(function() {
          if (sceneToRender && sceneToRender.activeCamera) {
            sceneToRender.render();
          }
        });
      });
    } else {
      setTimeout(() => {
        startInitScene();
      }, 400)
    }

  }
  startInitScene();

  function asyncAppendMeshes() {
    BABYLON.SceneLoader.AppendAsync("/scene/", local.meshList[local.appendMeshes], scene).then((meshes) => {
      if (local.appendMeshes < local.meshList.length - 1) {
        local.appendMeshes++;
        asyncAppendMeshes();
        local.loadProgress += 7;
      } else {
        afterAppendMeshes()
      }
    })
  }

  async function afterAppendMeshes() {
    scene.getMeshByName("Scooter").visibility=0;
    scene.getMeshByName("Arrow").position.y = -10;
    local.canRun = true;
    if(store.state.userProfile){
      if (store.state.userProfile.avatar != null && local.hero == null) {
        addHero(store.state.userProfile.avatar);
      }
    }else{
      if(store.state.user.isAnonymous && local.hero == null)
      {
        addHero(localStorage.getItem("avatar"));
      }
    }
    addGroundsAndWalls();
    addSomeToBeforeRender();
    local.sceneIsLoad = true;
  }
  async function startAfterLoadFunctions() {
    //addFreshnel(item,i);
    local.Gadt = BABYLON.GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI",scene);
    await objEnableSwith();
    addHighlighting(11);
    local.loadProgress += 5;
  }

  // Resize
  window.addEventListener("resize", function() {
    engine.resize();
  });

  //some before render actions
  function addSomeToBeforeRender() {
    local.observals.someActions = scene.onBeforeRenderObservable.add(function() {
      if (local.mouseDown && !local.iSayStay) {
        let pickInfo = scene.pick(local.eInfo.clientX, local.eInfo.clientY);
        movePrepare(pickInfo);
      }
      local.fps = engine.getFps().toFixed();
      //local.pointer.position = local.endPosition;
      local.RSpeed = 5.55 / engine.getFps().toFixed();
      local.WSpeed = 1.825 / engine.getFps().toFixed();
      local.rotSpeed = (.125 * engine.getFps().toFixed()) / 75;
      local.mRotSpeed = (.07 * 75) / engine.getFps().toFixed();
      local.distR = .95 + local.curSpeed * local.fps / 6;

      //  document.querySelector(".stat").innerHTML = "stat"+local.StatBOTS;
      //document.querySelector(".walk").innerHTML = "walk"+local.WalkBOTS+"free"+local.wTrueBots.length+"local.wTruePoints"+local.wTruePoints.length+"local.sTruePoints"+local.sTruePoints.length;
    })
  }

  //move functions
  function startHeroMove() {
    local.observals.mouseMove = scene.onBeforeRenderObservable.add(function() {
      if (local.fps > 15 && local.canMove && !local.inGame) {
        let curPos = new BABYLON.Vector3(local.hero.position.x, local.hero.position.y, local.hero.position.z);
        let endPos = new BABYLON.Vector3(local.endPosition.x, local.endPosition.y, local.endPosition.z);
        let dirPos = curPos.subtract(endPos).normalize();
        let forward = local.hero.forward;
        let rotDir = BABYLON.Vector3.Cross(forward, dirPos).y < 0 ? 1 : -1;
        if (curPos.subtract(endPos).length() < local.idleR) {
          blendTo("blend", 0, local.fps / 3);
        } else if (curPos.subtract(endPos).length() < local.walkR) {
          blendTo("blend", 1, local.fps / 2);
        } else {
          blendTo("blend", 2, local.fps / 2);
        }
        if (local.blend < 0.2) {
          local.soundStep.pause();
          BlendAnims(0);
        } else {
          local.soundStep.play();
          BlendAnims(local.blend);
        }
        local.hero.moveWithCollisions(local.hero.forward.scaleInPlace(local.curSpeed));
        //if(local.hero.position.y > local.endPosition.y)
        let deltaR = Math.abs(BABYLON.Vector3.Cross(forward, dirPos).y)
        if (deltaR > local.rotSpeed) {
          local.hero.rotation.y += local.rotSpeed * rotDir;
        }
      }
    });
  }

  function blendTo(name, to, det) {
    if (local[name] > to) {
      local[name] -= (1 / det);
    } else {
      local[name] += (1 / det);
    }
  }

  function goTo(param, to, det) {
    if (param > to) {
      param -= (1 / det);
    } else {
      param += (1 / det);
    }
    return param
  }

  function cameraZoom() {
    let input = document.querySelector("#js-camera-factor");
    local.heroCamera.radius = 6 + parseInt(input.value) / 1000
      local.curCamPos.copyFrom(local.heroCamera.position);
    input.oninput = () => {
      local.heroCamera.radius = 6 + parseInt(input.value) / 1000
      local.curCamPos.copyFrom(local.heroCamera.position);
      //let rule = parseInt(input.value)/10;
      //local.DN.blueMultiply.set(10/(255/rule),10/(255/rule),50/(255/rule));
    };
  }

  function startLazyCameraMk_2() {
    local.observals.lazyCam = scene.onBeforeRenderObservable.add(function() {
      if (local.fps > 15) {
        let camDist = new BABYLON.Vector3(local.camTarget.position.x, local.camTarget.position.y + 2.3, local.camTarget.position.z).subtract(local.cameraParent.position);
        let multyFloat = (camDist.length() / local.fps);
        if (isMobileOrTablet()) {
          multyFloat = camDist.length() / 10;
        }
        let camDistNorm = new BABYLON.Vector3(camDist.x, camDist.y, camDist.z).normalize().multiplyByFloats(multyFloat, multyFloat, multyFloat);
        local.cameraParent.position.x += camDistNorm.x;
        local.cameraParent.position.y += camDistNorm.y;
        local.cameraParent.position.z += camDistNorm.z;
      }
      // scene.onBeforeRenderObservable.remove(local.lazyCam);
    })
    local.observals.lazyCamR = scene.onBeforeRenderObservable.add(function() {
      if (local.fps > 15) {
        let camDist = local.camTarget.rotation.subtract(local.cameraParent.rotation);
        let multyFloat = (camDist.length() / local.fps);
        if (isMobileOrTablet()) {
          multyFloat = camDist.length() / 10;
        }
        let camDistNorm = new BABYLON.Vector3(camDist.x, camDist.y, camDist.z).normalize().multiplyByFloats(multyFloat, multyFloat, multyFloat);
        local.cameraParent.rotation.x += camDistNorm.x;
        local.cameraParent.rotation.y += camDistNorm.y;
        local.cameraParent.rotation.z += camDistNorm.z;
        // scene.onBeforeRenderObservable.remove(local.lazyCam);
      }
    })
  }
  //
  function timerTimer(isStart) {
    if (isStart) {
      local.timer = setInterval(() => {
        local.pointerTimer += .1
      }, 10)
    } else {
      clearInterval(local.timer);
    }
  }
  //emulate click on canvas
  function moveEventer() {
    local.pointerTimer = 0;
    local.thisCanvas = engine.getRenderingCanvas();
    local.mouseDown = false;
    local.thisCanvas.addEventListener('pointerdown', (e) => {
      local.pointerTimer = 0;
      timerTimer(true);
      local.mouseDown = true;
      local.eInfo = e;
      let pickInfo = scene.pick(e.clientX, e.clientY);
      movePrepare(pickInfo);
      if (pickInfo.pickedMesh) {
        //////console.log(pickInfo.pickedMesh.name);
        //if (pickInfo.pickedMesh.name.search("_EV_TYPE_1") > -1) {
        //    ////console.log(pickInfo.pickedMesh.name)
        //    interactiveScale(false,pickInfo.pickedMesh);
        //}
      }
    })
    local.thisCanvas.addEventListener('pointerup', () => {
      local.mouseDown = false;
      timerTimer(false);
      if (local.pointerTimer > 1) {
        local.endPosition = local.hero.position;
      }
    })
    local.thisCanvas.addEventListener('pointermove', (e) => {
      if (local.mouseDown) {
        local.eInfo = e;
      }
    })
  }

  function movePrepare(pickInfo) {
    // if the click hits the local.ground object, we change the impact position
    if (pickInfo.hit) {
    }
    if (
      pickInfo.hit && pickInfo.pickedMesh.name == "cGround"
    ) {
      local.endPosition = pickInfo.pickedPoint;
    }
  };

  //Animations Block
  function getAnimationGroup() {
    scene.getAnimationGroupByName("Idle").name = "heroIdle";
    local.idleAnim = scene.animationGroups.find(a => a.name === "heroIdle");
    local.idleParam = {
      name: "heroIdle",
      anim: local.idleAnim,
      weight: 1
    };
    local.idleAnim.play(true);
    local.idleAnim.setWeightForAllAnimatables(1);
    scene.getAnimationGroupByName("Walk").name = "heroWalk";
    local.walkAnim = scene.animationGroups.find(a => a.name === "heroWalk");
    local.walkParam = {
      name: "heroWalk",
      anim: local.walkAnim,
      weight: 0
    };
    local.walkAnim.play(true);
    local.walkAnim.setWeightForAllAnimatables(1);
    scene.getAnimationGroupByName("Run").name = "heroRun";
    local.runAnim = scene.animationGroups.find(a => a.name === "heroRun");
    local.runParam = {
      name: "heroRun",
      anim: local.runAnim,
      weight: 0
    };
    local.runAnim.play(true);
    local.runAnim.setWeightForAllAnimatables(1);
  }

  function BlendAnims(blend) {
    //idle
    local.idleParam.weight = 1 - clamp(blend, 0, 1);
    local.idleParam.anim.setWeightForAllAnimatables(local.idleParam.weight);
    //walk
    local.walkParam.weight = 1 - Math.abs(blend - 1);
    local.walkParam.anim.setWeightForAllAnimatables(local.walkParam.weight);
    //run
    local.runParam.weight = clamp(blend - 1, 0, 1);
    local.runParam.anim.setWeightForAllAnimatables(local.runParam.weight);
    //heroSpeed
    local.curSpeed = local.runParam.weight * local.RSpeed + local.walkParam.weight * local.WSpeed;
    if (blend < 0.01) {
      local.curSpeed = 0
    } //можэет сработает может нет надо затестить
  }

  function addShadows() {
    setTimeout(() => {
      local.shadowGenerator = new BABYLON.ShadowGenerator(4096, local.light);
      local.shadowGenerator.useBlurExponentialShadowMap = true;
      local.shadowGenerator.blurKernel = 8;
      //local.meshes.forEach((item) => {
      //    item.receiveShadows = false;
      //})
      //  scene.meshes.forEach(item =>{
      local.shadowGenerator.addShadowCaster(local.hero);
      scene.meshes.forEach((item, i) => {
        if (item.name.search("BOT") > -1) {
          local.shadowGenerator.addShadowCaster(item);
        }
      });

      //        })
    }, 15000)
  }


  //switch day/nightNANY??
  local.daySwitch = function(timeName) {
    if (timeName) {
      local.DN.blueMultiply.set(10 / 255, 10 / 255, 50 / 255);
      local.DN.roughness = 1;
      local.DN.emissiveIntensity = 1;
      local.DN.emissiveColor = new BABYLON.Color3(1, 1, 1);
      //  scene.getMeshByName("hdrSkyBox").material.reflectivityColor.set(50/255,50/255,50/255);
    }
  }

  local.daySwitchInit = function() {
    local.DN = {};
    local.DN.blueMultiply = new BABYLON.Color3(1, 1, 1);
    local.DN.emissiveColor = new BABYLON.Color3(0, 0, 0);
    local.DN.reflectionColor = new BABYLON.Color3(1, 1, 1);
    scene.meshes.forEach((item, i) => {
      if (item.name.search("star_") == -1 && item.name.search("BOT_") == -1 && item.name.search("ML_") == -1 && item.material != null && item.material.name != "Emissive") {
        item.material.albedoColor = local.DN.blueMultiply;
        scene.getMeshByName("hdrSkyBox").material.reflectivityColor = local.DN.blueMultiply;
        //item.material.maxSimultaneousLights = 20;
        if (item.name.search("Part") > -1) { //|| item.name=="Background"
          if(isMobileOrTablet()){
              if(item.name == "Part_Bridge"){
                item.material.emissiveColor = local.DN.emissiveColor;
              }
              item.material.reflectionColor = local.DN.reflectionColor;
          }else{
            item.material.emissiveColor = local.DN.emissiveColor;
            item.material.reflectionColor = local.DN.reflectionColor;
          }
        }
        scene.getMeshByName("Background").material.reflectionColor = local.DN.reflectionColor;
      }
    });
    local.observals["dayNight"] = scene.onBeforeRenderObservable.add(function() {
      if (Math.abs(local.cameraParent.position.y) > 10 && Math.abs(local.cameraParent.position.y) < 20) {
        let fact = (Math.abs((local.cameraParent.position.y - 14) * -1) * -1) + 2;
        fact = clamp(fact, 0, 1);
        //let fact = local.hero.position.y-10;
        local.DN.blueMultiply.set(20 / (20 + 235 * fact), 20 / (20 + 235 * fact), 50 / (50 + 235 * fact));
        local.DN.emissiveIntensity = fact;
        local.DN.emissiveColor.set(fact, fact, fact);
        local.DN.reflectionColor.set(20 / (20 + 235 * fact), 20 / (20 + 235 * fact), 50 / (50 + 235 * fact))
        scene.getMeshByName("Background").material.roughness = .5 + fact;
        //scene.getMeshByName("Background").material.emissiveColor.set(fact,fact,fact)
        //new BABYLON.Color3(206/255,220/255,220/255);
        scene.clearColor.set((206 - 186 * fact) / 255, (220 - 200 * fact) / 255, (220 - 170 * fact) / 255);
      }
    })
  }
  //
  function addGroundsAndWalls() {
    local.groundsNames.forEach(item => {
      let ground = scene.getMeshByName(item);
      ground.visibility = 0;
      ground.actionManager = new BABYLON.ActionManager(scene);
      ground.receiveShadows = true;
      ground.isPickable = true;
      ground.checkCollisions = false;
      local.grounds.push(ground);
    });
    local.collisionsNames.forEach(item => {
      let Wall = scene.getMeshByName(item);
      Wall.actionManager = new BABYLON.ActionManager(scene);
      Wall.isPickable = false;
      Wall.checkCollisions = true;
      Wall.visibility = 0;
    })
  }

  function addHeroGravitation(rootOfTarget, isBot) {
    let rayName = isBot ? rootOfTarget.name : "heroRay";
    rootOfTarget.rayBox = BABYLON.MeshBuilder.CreateBox("rayBox", {
      height: .1,
      width: .1,
      depth: .1
    }, scene);
    rootOfTarget.rayBox.scaling.set(10, 10, 10);
    rootOfTarget.rayBox.parent = rootOfTarget;
    rootOfTarget.rayBox.position = new BABYLON.Vector3(0, 0.7, 0);
    rootOfTarget.rayBox.visibility = 0;
    let ray = new BABYLON.Ray();
    let rayHelper = new BABYLON.RayHelper(ray);

    let localMeshDirection = new BABYLON.Vector3(0, -1 * Math.PI, 0);
    let length = 3;
    rayHelper.attachToMesh(rootOfTarget.rayBox, localMeshDirection, rootOfTarget.rayBox.position, length);
    //rayHelper.show(scene);
    local.observals[rayName] = scene.onBeforeRenderObservable.add(function() {
      rootOfTarget.rayBox.position = new BABYLON.Vector3(0, 10, 0)
      var hitInfo = ray.intersectsMeshes(local.grounds);
      if (hitInfo.length) {
        if (isBot) {
          rootOfTarget.position.y = hitInfo[0].pickedPoint.y + 1.1;
        } else {
          rootOfTarget.position.y = hitInfo[0].pickedPoint.y;
        }
      } else {
      }
    });
  }
  local.joystickSizes = {
    vertice: {
      static: {
        height: "25%",
        width: "45%",
        left: "-3%",
        top: "-9%",
      },
      inMove: {
        height: "75%",
        width: "85%",
        left: "17%",
        top: "16%",
        offsetL: .3,
        offsetT: .28,
      }
    },
    gorizontal: {
      static: {
        height: "25%",
        width: "45%",
        left: "2.5%",
        top: "-7%",
      },
      inMove: {
        height: "75%",
        width: "85%",
        left: "23%",
        top: "19%",
        offsetL: .225,
        offsetT: .25,
      }
    }
  }

  function addJoystic() {
    local.Jsizes = document.body.clientWidth < 420 ? local.joystickSizes.vertice : local.joystickSizes.gorizontal;
    local.Jblend = 0;
    local.observals.joystikMove = scene.onBeforeRenderObservable.add(function() {
      if (local.Jblend >= .1 && local.fps > 15) {
        BlendAnims(local.Jblend);
        local.hero.moveWithCollisions(local.hero.forward.scaleInPlace(local.RSpeed * (local.Jblend / 2)));
      }
    });

    let xAddPos = 0;
    let yAddPos = 0;
    window.xAddPos = 4000;
    window.yAddPos = 4000;
    window.xAddRot = 35000;
    local.adt = BABYLON.GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");
    let adt = local.adt;
    local.leftThCont = makeThumbArea("leftThumb", 2, "rgba(175,99,230,0)", null);
    local.leftThCont.height = local.Jsizes.static.height;
    local.leftThCont.width = local.Jsizes.static.width;
    local.leftThCont.isPointerBlocker = true;
    local.leftThCont.horizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
    local.leftThCont.verticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_BOTTOM;
    local.leftThCont.alpha = 1;
    local.leftThCont.left = local.Jsizes.static.left;
    local.leftThCont.top = local.Jsizes.static.top;

    local.leftInThCont = makeThumbArea("leftInnterThumb", 4, "rgb(175,99,230)", null);
    local.leftInThCont.height = "55px";
    local.leftInThCont.width = "55px";
    local.leftInThCont.isPointerBlocker = true;
    local.leftInThCont.horizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    local.leftInThCont.verticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_CENTER;
    local.leftInThCont.alpha = .8;


    local.leftPuck = makeThumbArea("local.leftPuck", 0, "rgb(175,99,230)", "rgb(175,99,230)");
    local.leftPuck.height = "45px";
    local.leftPuck.width = "45px";
    local.leftPuck.alpha = 0;
    local.leftPuck.isPointerBlocker = true;
    local.leftPuck.horizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
    local.leftPuck.verticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_CENTER;

    local.leftThCont.onPointerDownObservable.add(function(coordinates) {
      local.leftPuck.isVisible = true;
      local.leftPuck.floatLeft = adt._canvas.width - coordinates.x + (local.leftThCont._currentMeasure.width * local.Jsizes.inMove.offsetL) * -1; //local.sideJoyOff;
      local.leftPuck.left = local.leftPuck.floatLeft;
      local.leftPuck.floatTop = adt._canvas.height - coordinates.y + (local.leftThCont._currentMeasure.height * local.Jsizes.inMove.offsetT); //bottomJoystickOffset;
      local.leftPuck.top = local.leftPuck.floatTop * -1;
      local.leftPuck.isDown = true;
      local.leftThCont.height = local.Jsizes.inMove.height;
      local.leftThCont.width = local.Jsizes.inMove.width;
      local.leftThCont.left = local.Jsizes.inMove.left;
      local.leftThCont.top = local.Jsizes.inMove.top;
      //    local.leftThCont.alpha = 1;
    });

    local.leftThCont.onPointerUpObservable.add(function(coordinates) {
      xAddPos = 0;
      yAddPos = 0;
      local.leftPuck.isDown = false;
      local.leftPuck.isVisible = false;
      local.leftThCont.height = local.Jsizes.static.height;
      local.leftThCont.width = local.Jsizes.static.width;
      local.leftThCont.left = local.Jsizes.static.left;
      local.leftThCont.top = local.Jsizes.static.top;
      local.leftThCont.alpha = .8;
      local.leftPuck.alpha = 0;
    });

    local.leftThCont.onPointerMoveObservable.add(function(coordinates) {
      if (local.leftPuck.isDown) {
        xAddPos = (adt._canvas.width - coordinates.x - (local.leftThCont._currentMeasure.width * local.Jsizes.inMove.offsetL)) * -1;
        yAddPos = adt._canvas.height - coordinates.y - (local.leftThCont._currentMeasure.height * local.Jsizes.inMove.offsetT);
        local.leftPuck.floatLeft = xAddPos;
        local.leftPuck.floatTop = yAddPos * -1;
        local.leftPuck.left = local.leftPuck.floatLeft;
        local.leftPuck.top = local.leftPuck.floatTop;
        local.leftPuck.alpha = 0;
        local.leftThCont.height = local.Jsizes.inMove.height;
        local.leftThCont.width = local.Jsizes.inMove.width;
        local.leftThCont.left = local.Jsizes.inMove.left;
        local.leftThCont.top = local.Jsizes.inMove.top;
        local.leftPuck.alpha = .8;
      }
    });

    adt.addControl(local.leftThCont);
    local.leftThCont.addControl(local.leftInThCont);
    local.leftThCont.addControl(local.leftPuck);
    local.leftPuck.isVisible = false;

    scene.registerBeforeRender(function() {
      if (Math.abs(xAddPos / 50) > .25) {
        local.hero.rotation.y += (local.mRotSpeed * (xAddPos / 175) * .2);
      }
      if (local.leftPuck.isDown) {
        local.Jblend = yAddPos / 66 < 2 ? yAddPos / 50 : 2;
      } else {
        if (local.Jblend > .1) {
          blendTo("Jblend", 0, local.fps / 3);
        } else {
          local.Jblend = 0;
          BlendAnims(0);
        }
      }
    });
    window.addEventListener("resize", function() {
      local.Jsizes = document.body.clientWidth < 420 ? local.joystickSizes.vertice : local.joystickSizes.gorizontal;
      local.leftThCont.height = local.Jsizes.static.height;
      local.leftThCont.width = local.Jsizes.static.width;
      if (local.leftThCont.left != "1000px") {
        local.leftThCont.left = local.Jsizes.static.left;
      }
      local.leftThCont.top = local.Jsizes.static.top;
    });

    function makeThumbArea(name, thickness, color, background, curves) {
      let rect = new BABYLON.GUI.Ellipse();
      rect.name = name;
      rect.thickness = thickness;
      rect.color = color;
      rect.background = background;
      rect.paddingLeft = "0px";
      rect.paddingRight = "0px";
      rect.paddingTop = "0px";
      rect.paddingBottom = "0px";

      return rect;
    }
  }

  function addMapCamera() {
    let startBtn = document.querySelector(".js-button-map");
    startBtn.addEventListener("click", () => {
      localInt.soundWindow.currentTime = 0
      localInt.soundWindow.play()
      if (isMobileOrTablet()) {
        //тут надо будет скрывать джойстик
        local.adt.uScale = 0;
      } else {
        scene.onBeforeRenderObservable.remove(local.observals.mouseMove);
      }
      if (local.onMove) {
        cameraMapMove();
      } else {
        local.onMove = true;
        cameraMapMove();
      }
    })
  }

  function HighlightingTo(value, to) {
    for (i = 1; i < value; i++) {
      let item = scene.getMeshByName("ML_" + i);
      for (j = 1; j < 3; j++) {
        let item = scene.getMeshByName("ML_" + i + "_" + j);
      }
    }
  }

   window.addHighlighting = function(value) {
    let link = "callouts.glb";
    if(localStorage.getItem("lang")=="en"){
      document.querySelector(".js-upper-icon--language .icon_slider").classList.add("active");
      link="en_callouts.glb";
    }
    BABYLON.SceneLoader.AppendAsync("/scene/", link, scene).then(() => {
      local.disableArr = ["ML_1_1", "ML_1_2", "ML_2_1", "ML_2_2", "ML_3_1", "ML_3_2", "ML_4_1", "ML_4_2", "ML_5_1", "ML_5_2", "ML_6_1", "ML_6_2", "ML_7_1", "ML_7_2", "ML_8_1", "ML_8_2", "ML_9_1", "ML_9_2", "ML_10_1", "ML_10_2"];
      local.disableArr.forEach((item) => {
        scene.getMeshByName(item).isPickable = false;
      });

      for (let i = 1; i < value; i++) {
        let btn_1 = scene.getMeshByName("ML_" + i + "_primitive0");
        let btn_2 = scene.getMeshByName("ML_" + i + "_primitive1");
        let btn = BABYLON.MeshBuilder.CreateBox("ML_" + i, {
          height: 12,
          width: 41,
          depth: 3
        }, scene);
        local.mapBtns.push(btn, btn_1, btn_2);
        btn.visibility = 0;
        let newPos = btn_2.getAbsolutePosition();
        local.mapBtns.forEach(item => {
          item._isEnabled = false;
        })
        local.disableArr.forEach(item => {
          scene.getMeshByName(item)._isEnabled = false;
        });

        btn.position = new BABYLON.Vector3(newPos.x, newPos.y + 5, newPos.z);
        btn.actionManager = new BABYLON.ActionManager(scene);
        btn.actionManager.registerAction(
          new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, (() => {
            mapLinkScale(btn, 0, 3.25 * Math.PI / 4, true, "vector3", "scaling", 15);
            mapLinkScale(btn_1, 0, 3.25 * Math.PI / 4, true, "vector3", "scaling", 15);
            mapLinkScale(btn_2, 0, 3.25 * Math.PI / 4, true, "vector3", "scaling", 15);
            setTimeout(() => {
              mapLinkScale(scene.getMeshByName(btn.name + "_2"), 3 * Math.PI / 2, 2 * Math.PI, true, "float", "visibility", 45);
            }, 7000 / local.fps)
            setTimeout(() => {
              mapLinkScale(scene.getMeshByName(btn.name + "_1"), 3 * Math.PI / 2, 3 * Math.PI, true, "float", "visibility", 45);
            }, 7000 / local.fps)
          }))
        )
        btn.actionManager.registerAction(
          new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, (() => {
            mapLinkScale(btn, 3.25 * Math.PI / 4, Math.PI, false, "vector3", "scaling", 15);
            mapLinkScale(btn_1, 3.25 * Math.PI / 4, Math.PI, false, "vector3", "scaling", 15);
            mapLinkScale(btn_2, 3.25 * Math.PI / 4, Math.PI, false, "vector3", "scaling", 15);
            setTimeout(() => {
              mapLinkScale(scene.getMeshByName(btn.name + "_2"), Math.PI, 3 * Math.PI / 2, false, "float", "visibility", 45);
            }, 7000 / local.fps)
            setTimeout(() => {
              mapLinkScale(scene.getMeshByName(btn.name + "_1"), Math.PI, 3 * Math.PI / 2, false, "float", "visibility", 45);
            }, 10000 / local.fps)
          }))
        )
        btn.actionManager.registerAction(
          new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, (function() {
            mapPrepare(btn);
            mapLinkScale(scene.getMeshByName(btn.name + "_2"), Math.PI, 3 * Math.PI / 2, false, "float", "visibility", 45);
            mapLinkScale(scene.getMeshByName(btn.name + "_1"), Math.PI, 3 * Math.PI / 2, false, "float", "visibility", 45);
          })))
        rotateHeaders(btn_1, btn);
        rotateHeaders(btn_2, false);
        for (let j = 1; j < 3; j++) {
          let item = scene.getMeshByName("ML_" + i + "_" + j);
          item.visibility = 0;
        }
      }
    })

    function mapPrepare(mesh) {
      if (local.camTarget.targetName == "map") {
        if (local.onMove) {
          local.soundClick.currentTime = 0
          local.soundClick.play()
          local.onMove = false;
          if (isMobileOrTablet()) {} else {
            scene.onBeforeRenderObservable.remove(local.observals.mouseMove);
          }
          let pos = local.MapPositions[mesh.name];
          local.endPosition = new BABYLON.Vector3(pos.x, pos.y, pos.z);
          local.hero.position = new BABYLON.Vector3(pos.x, pos.y, pos.z);
          local.hero.rotation = new BABYLON.Vector3(
            local.MapPositions[mesh.name + "_R"].x,
            local.MapPositions[mesh.name + "_R"].y,
            local.MapPositions[mesh.name + "_R"].z
          );
          setTimeout(() => {
            cameraMapMove()
          }, 20)
        }
      }
    }


    function mapLinkScale(mesh, from, to, appearance, type, name, tiks) {
      scene.onBeforeRenderObservable.remove(local.observals[mesh.name + "_scale"]);
      mesh.isEnableScale = true;
      let scalingNum = from;
      let tik = 1;
      local.observals[mesh.name + "_scale"] = scene.onBeforeRenderObservable.add(function() {
        scalingNum += (to - from) / (tiks + tik);
        if (appearance) {
          tik++;
        } else {
          tik--;
        }
        let scaling = 1 + Math.sin(scalingNum);
        if (type == "vector3") {
          mesh[name] = new BABYLON.Vector3(scaling, scaling, scaling);
        }
        if (type == "float") {
          mesh[name] = scaling;
        }
        if (scalingNum > to) {
          scene.onBeforeRenderObservable.remove(local.observals[mesh.name + "_scale"]);
          return
        }
      })
    }
  }

  function rotateHeaders(item, actionBtn) {
  local.observals[item.name] = scene.onBeforeRenderObservable.add(function() {
      var cPos3D = local.heroCamera.position;
      var tPos3D = item.position;
      cPos3D = cPos3D.subtract(tPos3D);
      var tPos2D = new BABYLON.Vector2(cPos3D._x, cPos3D._z);
      var tRotY = BABYLON.Angle.BetweenTwoPoints(new BABYLON.Vector2(0, 0), tPos2D);
      item.rotation = new BABYLON.Vector3(0, 0, tRotY._radians * (-1) + 1.57 + local.hero.rotation.y);
      if (actionBtn) {
        actionBtn.rotation.y = tRotY._radians * (-1) + 1.57 + local.hero.rotation.y;
      }
    })
  }

  function cameraMapMove() {
    let mapTargetPos = new BABYLON.Vector3(25, -25, 25);
    if (local.camTarget.targetName == "hero" && local.onMove) {
      scene.onBeforeRenderObservable.remove(local.observals.heroRay);
      scene.onBeforeRenderObservable.remove(local.observals.lazyCamRad);
      document.querySelector(".camera-factor").classList.add("ingame-cam")
      local.onMove = false;
      local.camTarget.position = mapTargetPos;
      local.camTarget.rotation = local.hero.rotation;
      local.camTarget.rotation.y += 1.57;
      local.camTarget.targetName = "map";
      local.mapBtns.forEach(item => {
        item._isEnabled = true;
      })
      local.disableArr.forEach(item => {
        scene.getMeshByName(item)._isEnabled = true;
      });
      scene.getMeshByName("Background").visibility = 0;
      let allWay = local.cameraParent.position.subtract(local.camTarget.position)
      local.observals.lazyCamRad = scene.onBeforeRenderObservable.add(function() {
        let deltato = 1 - ((allWay.length() - local.cameraParent.position.subtract(local.camTarget.position).length()) / allWay.length());
        if (local.heroCamera.radius < 320) {
          local.heroCamera.radius = 340 - (340 * deltato);
        } else {
          scene.onBeforeRenderObservable.remove(local.observals.lazyCamRad);
          local.heroCamera.lowerRadiusLimit = local.heroCamera.radius;
          local.heroCamera.upperRadiusLimit = local.heroCamera.radius;
          local.onMove = true;
          scene.cameras[0].attachControl(true);
        }
      })
    } else {
      local.heroCamera.lowerRadiusLimit = null;
      local.heroCamera.upperRadiusLimit = null;
      local.onMove = false;
      local.heroCamera.detachControl(true);
      local.camTarget.position = local.hero.position;
      local.hero.rotation = local.camTarget.rotation;
      local.hero.rotation.y += 1.57;
      local.camTarget.targetName = "hero";
      scene.onBeforeRenderObservable.remove(local.observals.lazyCamRad);
      local.observals.lazyCamRad = scene.onBeforeRenderObservable.add(function() {
        let nextCamPos = new BABYLON.Vector3(local.curCamPos.x, local.curCamPos.y, local.curCamPos.z);
        if (local.heroCamera.position.subtract(nextCamPos).length() > 0.03) {
          local.endPosition = new BABYLON.Vector3(local.hero.position.x, local.hero.position.y, local.hero.position.z);
          let deltaPos = nextCamPos.subtract(local.heroCamera.position).multiplyByFloats(2 / local.fps, 2 / local.fps, 2 / local.fps);
          local.heroCamera.position = new BABYLON.Vector3(
            local.heroCamera.position.x + deltaPos.x,
            local.heroCamera.position.y + deltaPos.y,
            local.heroCamera.position.z + deltaPos.z
          );
        } else {
          local.mapBtns.forEach(item => {
            item._isEnabled = false;
          })
          local.disableArr.forEach(item => {
            scene.getMeshByName(item)._isEnabled = false;
          });
          scene.getMeshByName("Background").visibility = 1;
          scene.onBeforeRenderObservable.remove(local.observals.heroRay);
          scene.onBeforeRenderObservable.remove(local.observals.lazyCamRad);
          document.querySelector(".camera-factor").classList.remove("ingame-cam")
          if (isMobileOrTablet()) {
            local.adt.uScale = 1;
            addHeroGravitation(local.hero, false);
          } else {
            addHeroGravitation(local.hero, false);
            startHeroMove();
          }
          local.onMove = true;
        }
      })
    }
  }

  function InfoBTNS(item) {
    item.actionManager = new BABYLON.ActionManager(scene);
    item.actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, (function() {
        vexpo.setObjectForCard(item.name.replace("SM_", ""));
        document.querySelector(".header").classList.remove("disable");
      })))
  }

  function objEnableSwith() {
    return new Promise(resolve => {
      scene.meshes.forEach((item, i) => {
        if (item.name.search("SM_") > -1) {
          local.objLinks.push(item);
          item._isEnabled = false;
        }
        if (i == scene.meshes.length - 1) {
          if(!store.state.user.isAnonymous){
            generateInteractiveStar(scene.getMeshByName("star_02"), "intStars", 14);
          }

          local.spriteGicon = new BABYLON.SpriteManager("inconSprite", "/images/Play.png", 5, {
            width: 128,
            height: 128
          }, scene);
          local.spriteGicon.renderingGroupId = 1;
          local.spriteGicon.isPickable = true;
          initMCsprites();
          initGorodki(local, scene, local.spriteGicon);
          initFootball(local, scene, local.spriteGicon);
          initWaste(local, scene, local.spriteGicon);
          if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 || navigator.platform === 'iPhone') {
          }else{
            initSamokat(local, scene, local.spriteGicon,new BABYLON.Vector3(61.45, 1.78, 4.37));
          }
          generateIconSprites("intBtns", local.AllBtns.length);
          startCheckDist();
          resolve(true);
        }
      });
    });
  }
  function initMCsprites(){
    local.spriteMCicon = new BABYLON.SpriteManager("inconMCSprite", "/images/Class.png", linkArr.linkArr.length, {
      width: 128,
      height: 128
    }, scene);
    local.spriteMCicon.renderingGroupId = 1;
    local.spriteMCicon.isPickable = true;
    linkArr.linkArr.forEach((item, i) => {
      let sprite = new BABYLON.Sprite(item.name, local.spriteMCicon);
      sprite.position = new BABYLON.Vector3(item.pos[0], item.pos[1], item.pos[2]);
      sprite.isPickable = true;
      sprite.height = .5;
      sprite.width = .5;
      sprite.playAnimation(0, 0, true, 40);
    });

  }
  function generateIconSprites(name, count) {
    let spriteManagerTrees = new BABYLON.SpriteManager("inconSprite", "/images/Info.png", count, {
      width: 128,
      height: 128
    });
    spriteManagerTrees.renderingGroupId = 1;
    spriteManagerTrees.isPickable = true;
    local.AllBtns.forEach((item, i) => {
      let sprite = new BABYLON.Sprite(item.name, spriteManagerTrees);
      sprite.position = new BABYLON.Vector3(item.pos[0], item.pos[1], item.pos[2]);
      sprite.isPickable = true;
      sprite.height = .5;
      sprite.width = .5;
      sprite.playAnimation(0, 0, true, 40);
    })
    scene.onPointerDown = function(evt) {
      var pickResult = scene.pickSprite(this.pointerX, this.pointerY);
      if (pickResult.hit) {
        function stay(){
          local.mouseDown = false;
          local.iSayStay = true;
          setTimeout(() => {
            local.mouseDown = false;
            local.iSayStay = false;
            local.endPosition = local.hero.position;
          }, 100)
        }
        local.soundWindow.play();
        if (pickResult.pickedSprite.name.search("SM") > -1) {
          stay();
          vexpo.setObjectForCard(pickResult.pickedSprite.name.replace("SM_", ""));
          document.querySelector(".header").classList.remove("disable");
        }
        if (pickResult.pickedSprite.name.search("Game") > -1) {

          if(!store.state.user.isAnonymous){
            if (pickResult.pickedSprite.name =="Game_samokat" && store.state.userProfile.Game_samokat==0){
                counterStar(10);
                store.dispatch("updateUserProfile", {Game_samokat: 1});
            }
            if (pickResult.pickedSprite.name =="Game_Football" && store.state.userProfile.Game_Football==0){
                counterStar(10);
                store.dispatch("updateUserProfile", {Game_Football: 1});
            }
            if (pickResult.pickedSprite.name =="Game_gorodki" && store.state.userProfile.Game_gorodki==0){
                counterStar(10);
                store.dispatch("updateUserProfile", {Game_gorodki: 1});
            }
            if (pickResult.pickedSprite.name =="Game_waste" && store.state.userProfile.Game_waste==0){
                store.dispatch("updateUserProfile", {Game_waste: 1});
                counterStar(10);
            }
          }

          stay();
          if(pickResult.pickedSprite.name =="Game_samokat"){
            local.Games[pickResult.pickedSprite.name](pickResult.pickedSprite.position);
          }else{
            local.Games[pickResult.pickedSprite.name](scene,local);
          }
          if (pickResult.pickedSprite.name.search("waste") == -1) {
            local.spriteGicon.renderingGroupId = -1;
          }
        }
        if(pickResult.pickedSprite.name.search("MC_") > -1){
            stay();
            window.msKlass = pickResult.pickedSprite.name;
            window.vexpo.msKlassON=true;
            setTimeout(()=>{
              document.querySelector(".master-block").classList.add("anim-master-go")
            },10)
            //window.open(pickResult.pickedSprite.name, "_blank");
            //alert (pickResult.pickedSprite.name);
        }
      }
      if (local.ANote0VideoVidTex && local.ANote0VideoVidTex.video.paused) {
        local.ANote0VideoVidTex.video.play();
      }
    };
    scene.onPointerMove = function(evt) {
      let pickResult = scene.pickSprite(this.pointerX, this.pointerY);
      if (pickResult.hit) {
        canvas.style.cursor = "pointer";
      } else {
        canvas.style.cursor = "";
      }
    }
  }

  function generateInteractiveStar(mesh, name, count) {
    mesh.setParent(null);
    initShaderStar();
    mesh.position.y = -100;
    local[name].push(mesh);
    for (var i = 0; i < count; i++) {
      let instance = mesh.createInstance(mesh.name + "_instance");
      local[name].push(instance);
    }
  };

  function initShaderStar() {
    let effect = scene.getMeshByName("Graph");
    effect.parent = local.hero;
    effect.scaling.set(4.5,4.5,4.5);
    BABYLON.Effect.ShadersStore.starVertexShader = `
      precision highp float;
      // Attributes
      attribute vec3 position;
      attribute vec3 color;
      attribute vec3 normal;
      attribute vec2 uv;
      attribute vec2 uv2;
      // Uniforms
      uniform mat4 worldViewProjection;
      uniform float T;
      // Varying
      varying vec4 Cd;
      void main(void) {
        float r = uv.x+3.;
        vec3 pos = position;
        pos.y += clamp(uv.x+T*2.,0.,1.)*uv.y*r*1.2;
        pos += normal*uv2.x*(1.-clamp(1.-abs((uv.x+T*2.)*2.-1.),0.,1.));
        float s = sin(-T*uv.y*4.);
        float c = cos(-T*uv.y*4.);
        mat2 m = mat2(c, -s, s, c);
        vec2 p2 = pos.xz*m;
        gl_Position = worldViewProjection * vec4(p2.x, pos.y, p2.y,1.);
        Cd = vec4(color.rgb,uv2.y);
      }
   `;
    BABYLON.Effect.ShadersStore.starFragmentShader = `
          precision highp float;
          varying vec4 Cd;
          void main(void) {
            gl_FragColor = vec4(Cd.rgb,1.);
            if (Cd.w<.1) discard;
          }

      `;
    local.starShader = new BABYLON.ShaderMaterial("shaderGradient", scene, "star", {
      attributes: ["uv2", "uv", "color", "position", "normal"],
      defines: ["#define INSTANCES"],
      uniforms: ["T", "world", "worldViewProjection"]
    });
    //  mainTextureA.updateSamplingMode(BABYLON.Texture.NEAREST_SAMPLINGMODE);
  local.starShader.setFloat("T", 0);
    //ljnhjhvhgxrt
    effect.material = local.starShader;
  }


 function counterStar(plus) {
  let counter = document.querySelector(".js-counter_number");
  local.starsCount  =  parseInt(store.state.userProfile.balance)+plus;
  counter.setAttribute("data-count", local.starsCount);
  counter.innerHTML = local.starsCount;
  document.querySelector(".js-head-counter_number").innerHTML = local.starsCount;
  store.dispatch("updateUserProfile", {balance: local.starsCount});
}
function startStarInteraction(){
  if(local.observals["StarInteraction"]!=null){
    scene.onBeforeRenderObservable.remove(local.observals["StarInteraction"]);
  }
    let i=0;
    local.observals["StarInteraction"] = scene.onBeforeRenderObservable.add(()=>{
    i+= 1/local.fps;
    if(i>1){
      scene.onBeforeRenderObservable.remove(local.observals["StarInteraction"]);
    }
    local.starShader.setFloat("T", i);
    document.querySelector(".point-detected").classList.add('give-scale')
    setTimeout(()=>{
      document.querySelector(".point-detected").classList.remove('give-scale')
    },200)
  })
};

function startCheckDist() {
  if (local.hero != null) {
    local.observals["objEnabledSwith"] = scene.onBeforeRenderObservable.add(() => {
      if (local.prevChekPos.subtract(local.hero.position).length() > .25) {
        local.intStars.forEach((item, i) => {
          if (item.getAbsolutePosition().subtract(local.hero.position).length() < 2) {
            local.soundStar.currentTime = 0;
            local.soundStar.play()
            startStarInteraction();
            let index = local.AllStars.findIndex(star => star.name == item.name);
            if (index > -1) {
              local.AllStars.splice(index, 1)
              store.dispatch("collectStar", {starId: item.name});
              setTimeout(() => {
                counterStar(1)
              }, 50)
            }
            item.position.y = -100;
          }
        });
      }
      if (local.prevChekPos.subtract(local.hero.position).length() > 3) {
        replaceIntObjects("intStars", "AllStars");
          switchIntSprites(0,16);
          switchIntSprites(1,16);
          switchIntSprites(2,8);
      };
      if (local.prevChekPos.subtract(local.hero.position).length() > 10) {
        local.prevChekPos.set(local.hero.position.x, local.hero.position.y, local.hero.position.z);
        local.objLinks.forEach((item, i) => {
          if (item.getAbsolutePosition().subtract(local.hero.position).length() < 110) {
            item._isEnabled = true;
          } else {
            item._isEnabled = false;
          }
        });
      };
    });
  } else {
    setTimeout(() => {
      startCheckDist();
    }, 1000)
  }
}

function switchIntSprites(num,rad) {
  scene.spriteManagers[num].sprites.forEach((item, i) => {
    if (item.position.subtract(local.hero.position).length() < rad) {
      if (!item.isVisible) {
        item.playAnimation(0, 18, false, 20);
        setTimeout(() => {
          item.playAnimation(18, 42, true, 40);
        }, 360)
        item.isVisible = true;
      }
    } else {
      if (item.isVisible) {
        item.isVisible = false;
        item.playAnimation(0, 18, false, 20);
        setTimeout(() => {
          item.playAnimation(0, 0, true, 40);
        }, 360)
      }
    }
  })
}

function replaceIntObjects(objArr, pointsArr) {
  let truePoints = [];
  let trueElems = [];
  let itogo = 0;
  local[objArr].forEach((item, i) => {
    if (Math.abs(item.position.subtract(local.hero.position).length()) > 25) {
      trueElems.push(item);
    }
  });
  local[pointsArr].forEach((item, i) => {
    if (!item.isIcon && Math.abs(new BABYLON.Vector3(item.pos[0], item.pos[1], item.pos[2]).subtract(local.hero.position).length()) < 25) {
      truePoints.push(item);
    }
    if (i == local[pointsArr].length - 1) {
      truePoints.forEach((item, i) => {
        if (trueElems[i] != undefined) {
          let index = local[pointsArr].findIndex(arrItem => arrItem.name == item.name);
          local[pointsArr][index].isIcon = true;
          trueElems[i].position.set(item.pos[0], item.pos[1], item.pos[2]);
          trueElems[i].name = item.name;
          //	scalinginfo(trueElems[i]);
        }
        itogo++
      });
    }
  });
}

function addHero(name) {
  if (local.hero != null) {
    return
  };
  BABYLON.SceneLoader.ImportMesh("", "/scene/choise/", name + ".glb", scene, function(newMeshes, particleSystems, skeletons, animationGroups) {
    local.loadProgress += 5;
    local.hero = newMeshes[0];
    local.heroGeometry = newMeshes[1];
    local.heroGeometry.material.roughness=1;
    //local.hero.showBoundingBox = true;
    local.hero.checkCollisions = true;
    local.meshes = newMeshes;
    local.skeleton = skeletons[0];
    local.hero.scaling.scaleInPlace(1);
    local.hero.scaling.set(.1, .1, -.1);

    if (localStorage.getItem("pos") != null) {
      let pos = localStorage.getItem("pos").split("#");
      local.hero.position.set(parseFloat(pos[0]), parseFloat(pos[1]), parseFloat(pos[2]));
    } else {
      local.hero.position = new BABYLON.Vector3(-9.532124217796117, 9.127684593200684, -14.59875922189138);
    }

    local.prevChekPos = new BABYLON.Vector3(-100, 100, 100);
    local.hero.rotation = new BABYLON.Vector3(0, 2.4726780506964454, 0);
    local.cameraParent = BABYLON.MeshBuilder.CreateSphere("cameraParent", {
      diameterX: .25,
      diameterY: 0.25,
      diameterZ: 0.25
    }, scene);
    local.cameraParent.position = new BABYLON.Vector3(local.hero.position.x, local.hero.position.y + 2.3, local.hero.position.z) //new BABYLON.Vector3( 0, 87,0);
    local.cameraParent.rotation = new BABYLON.Vector3(local.hero.rotation.x, local.hero.rotation.y, local.hero.rotation.z) //new BABYLON.Vector3( 0, 87,0);
    local.cameraParent.visibility = 0;
    local.heroCamera.parent = local.cameraParent;
    //local.cameraParent.rotation = new BABYLON.Vector3(local.cameraParent.rotation.x, newMeshes[0].rotation.y+Math.PI, local.cameraParent.rotation.z);
    // addShadows();
    //Hero character variables
    local.heroRotationSpeed = 0.1;
    local.animating = false;
    local.hero.rotationQuaternion = null;
    //local.hero.showBoundingBox = true;
    local.endPosition = local.hero.position;
    local.deltaSpeed = local.heroMaxSpeed / engine.getFps().toFixed();
    local.RSpeed = (4.25 * 3.25) / engine.getFps().toFixed();
    local.WSpeed = (1.825 * 1.5) / engine.getFps().toFixed();
    local.fps = engine.getFps().toFixed();
    local.camTarget = {};
    local.camTarget.targetName = "hero";
    local.camTarget.position = local.hero.position;
    local.camTarget.rotation = local.hero.rotation;
    getAnimationGroup();
    local.currentParam = local.idleParam;
    if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 || navigator.platform === 'iPhone') {
      local.daySwitchInit();
    } else {
      local.daySwitchInit();
    }
    if (isMobileOrTablet()) {
      addJoystic();
      local.loadProgress += 7;
    } else {
      moveEventer();
      startHeroMove();
      local.loadProgress += 7;
    }
    setTimeout(() => {
      addHeroGravitation(local.hero, false);
      local.loadProgress += 7;
    }, 150)
    setTimeout(() => {
      startLazyCameraMk_2();
      cameraZoom();
      local.loadProgress += 5;
    }, 300)
    setTimeout(() => {
      addMapCamera();
      local.curCamPos.copyFrom(local.heroCamera.position);
      local.loadProgress += 10;
    }, 450)
    setTimeout(() => {
      if (isMobileOrTablet()) {
        engine.setHardwareScalingLevel(1);
      }
      if (navigator.platform === 'iPhone') {
        engine.setHardwareScalingLevel(.75);
        local.allLoad = true;
      } else {
        loadBots();
      }
    }, 900);

    window.setDataHeroPos = setInterval(() => {
      localStorage.setItem("pos", local.hero.position.x + "#" + local.hero.position.y + "#" + local.hero.position.z);
    }, 1000);
    killLoadScreen();
    disableGrounds();

    function disableGrounds() {
      if (local.canRun) {
        scene.meshes.forEach((item, i) => {
          if (item.name == "cGround" || item.name.search("icon") > -1) {
            item.isPickable = true;
          } else {
            item.isPickable = false;
          }
          if (i == scene.meshes.length - 1) {
            startAfterLoadFunctions();
          }
        })
      } else {
        setTimeout(() => {
          disableGrounds()
        }, 400)
      }
    }
  });
}

function killLoadScreen() {
  if (local.sceneIsLoad && local.allLoad) {
    localStorage.setItem('insceneOk', 1);
    setTimeout(() => {
      document.querySelector("header").classList.add('disable');
      document.querySelector('.header').classList.add("griz");
      if (window.isfirstTime) {
        document.querySelector(".js-training-intro").classList.add('active');
        document.querySelector(".right-item--train").classList.add("active");
        document.querySelector(".training-main").classList.add('active');
        document.querySelector(".glass-plane").classList.add('active');
        document.querySelector(".right-bar").classList.add('train');
        document.querySelectorAll(".right-item").forEach((item) => {
          item.classList.add('train');
        })
        document.querySelector(".point-detected").classList.add("disable");
        document.querySelector(".nav-icon3").classList.add("disable");
      } else {
        document.querySelector(".right-item--train").remove();
        let trainBlock = document.querySelectorAll(".training-main");
        trainBlock.forEach((item) => {
          item.remove()
        })
      }
      lottie.destroy();
      let music = document.querySelector('.sound-scene')
      document.querySelector('body').addEventListener('click', () => {
        if (music.hasAttribute('isPlayed')) {
          return;
        }
        music.volume = 0.1
        music.play();
        music.setAttribute("isPlayed", "true")
      })
      document.querySelector('.sound_back').addEventListener('click', () => {
        local.soundClick.currentTime = 0
        local.soundClick.play()
        if (music.getAttribute('isPlayed') == "true") {
          music.volume = 0;
          local.soundStep.volume = 0;
          local.soundWindow.volume = 0;
          local.soundClick.volume = 0;
          local.soundHover.volume = 0;
          local.soundStar.volume = 0;
          local.soundCast.volume = 0;
          local.soundWin.volume = 0;
          local.soundKick.volume = 0;
          local.soundFail.volume = 0;
          local.soundAlert.volume = 0;
          music.pause();
          music.setAttribute("isPlayed", "false");
        } else {
          local.soundStep.volume = 1;
          local.soundWindow.volume = 1;
          local.soundClick.volume = 0.1;
          local.soundHover.volume = 0.1;
          local.soundStar.volume = 1;
          local.soundCast.volume = 1;
          local.soundWin.volume = 1;
          local.soundKick.volume = 1;
          local.soundFail.volume = 1;
          local.soundAlert.volume = 0.1;
          music.volume = 0.2;
          music.play();
          music.setAttribute("isPlayed", "true");
        }
      })
      if (document.getElementById("customLoadingScreenDiv") != null) {
        document.getElementById("customLoadingScreenDiv").remove();
      }
    }, 900);
  } else {
    local.hero.checkCollisions = true;
    setTimeout(() => {
      killLoadScreen()
    }, 1500);
  }
}
}

function addVideoTex() {
  local.videos.forEach(item => {
    let mesh = scene.getMeshByName(item);
    let video = '/videos/' + item + '.mp4';
    let ANote0VideoMat = new BABYLON.StandardMaterial("m", scene);
    local.ANote0VideoVidTex = new BABYLON.VideoTexture("vidtex", video, scene, {
      autoUpdateTexture: true
    });
    ANote0VideoMat.diffuseTexture = local.ANote0VideoVidTex;
    local.ANote0VideoVidTex.video.pause();
    ANote0VideoMat.emissiveTexture = local.ANote0VideoVidTex;
    //ANote0VideoMat.emissiveColor = new BABYLON.Color3.White();
    mesh.material = ANote0VideoMat;
  });
}
//////BOTS AREA
function loadBots() {
  local.botNum = 0;
  local.totBotNum = 7;
  addEXRBots("BOT_" + local.botNum, "");
  startPointsWalk(20);
  checkBotsPos();
  // Optimizer
  //local.allLoad = true;
  SHSLmfk();
  if(!isMobileOrTablet()){
    addVideoTex();
  }

}

function SHSLmfk() {
  if (local.botNum == local.totBotNum && local.setHardwareScalingLevel < 1.3 && local.fps < 40) {
    local.setHardwareScalingLevel += .1
    engine.setHardwareScalingLevel(local.setHardwareScalingLevel);
  } else {
    setTimeout(() => {
      if (local.botNum < local.totBotNum || local.setHardwareScalingLevel < 1.3 && local.fps < 40) {
        SHSLmfk();
      } else {
        local.allLoad = true;
        return;
      }
    }, 250);
  }
}

//это блин последняя версия
function addEXRBots(botName, botsArr) {
  local.loadProgress += 2;
  return new Promise(resolve => {
    if (local.botNum == local.totBotNum) {
      local.botsIsReady = true;
      resolve(true);
      return;
    }

    let pos;
    let mesh = {};
    local.botsdRanges = [
  [
    0.3,
    15.7
  ],
  [
    17.3,
    53.7
  ],
  [
    55.3,
    123.7
  ],
  [
    125.3,
    175.7
  ],
  [
    177.3,
    227.7
  ],
  [
    229.3,
    273.7
  ],
  [
    275.3,
    334.7
  ],
  [
    336.3,
    402.7
  ],
  [
    404.3,
    466.7
  ],
  [
    468.3,
    503.7
  ]
];
    let totalFrames = 1024;

    let animTexture = new BABYLON.Texture("/scene/bots/" + botName + "/" + botName + ".png");
    BABYLON.SceneLoader.AppendAsync("/scene/bots/" + botName + "/", botName + ".glb", scene).then(() => {
      mesh = scene.getMeshByName("Bots");
      mesh.setParent(null);
      mesh.name = botName;
      mesh.registerInstancedBuffer("animation", 4);
      mesh.registerInstancedBuffer("time", 2);
      mesh.instancedBuffers.animation = new BABYLON.Vector4(Math.random(), Math.random(), Math.random(), Math.random());
      mesh.instancedBuffers.time = new BABYLON.Vector2(Math.random(), Math.random());
      mesh.scaling.set(.02, .02, .02);
      mesh.rotation.y = Math.PI;
      mesh.setEnabled(false);
      creatingMat(mesh, pos, totalFrames, animTexture);
      startBotsClone(mesh, local.botsdRanges, botName, botsArr, true);
      if (local.botNum == local.totBotNum) {
        local.botsIsReady = true;
        resolve(true);
      } else {
        local.botNum++;
        setTimeout(() => {
          addEXRBots("BOT_" + local.botNum, "");
        }, 500)
        resolve(true);
      }
    })
  })
}

function setAnimation(mesh, animName, colorIndex, isWalk) {
  let start = local.botsdRanges[animName][0];
  let end = local.botsdRanges[animName][1];
  let color = colorIndex != null ? parseInt(colorIndex) : parseInt(mesh.instancedBuffers.animation.z);
  if (mesh.obsTime != null) {
    scene.onBeforeRenderObservable.remove(mesh.obsTime);
    mesh.obsTime = null;
  }
  mesh.T = 0;
  mesh.instancedBuffers.animation = new BABYLON.Vector4(start, end, color, 0);
  mesh.instancedBuffers.time = new BABYLON.Vector2(0, 0);
  if (isWalk) {
    mesh.obsTime = scene.onBeforeRenderObservable.add(function() {
      mesh.T += 1 / engine.getFps();
      mesh.instancedBuffers.time.x = mesh.T * 13;
    })
  } else {
    mesh.obsTime = scene.onBeforeRenderObservable.add(function() {
      if (mesh.T > (end - start) / (7.5 * .75) && local.fps > 15) {
        setAnimation(mesh, randomIntFromInterval(1, local.botsdRanges.length - 2), null, false);
      }
      mesh.T += 1 / engine.getFps();
      mesh.instancedBuffers.time.x = mesh.T * (7.5 * .75);
    })
  }
  if (mesh.obs == null) {
    mesh.obs = scene.registerBeforeRender(() => {
      mesh.instancedBuffers.animation.w = mesh.getAbsolutePosition().subtract(local.hero.getAbsolutePosition()).length();
    })
  }
}

function randomIntFromInterval(min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function creatingMat(mesh, pos, totalFrames, animTexture) {
  return new Promise(resolve => {
    BABYLON.Effect.ShadersStore.customVertexShader = `
    // Attributes
    attribute vec2 uv;
    attribute vec2 uv2;
    attribute vec4 world0;
    attribute vec4 world1;
    attribute vec4 world2;
    attribute vec4 world3;
    attribute vec4 animation;
    attribute vec2 time;
    attribute vec3 position;
    attribute vec3 normal;
    // Uniforms
    uniform mat4 viewProjection;
    uniform float totalFrames;
    uniform highp sampler2D texAnim;
    uniform mat4 world;
    uniform vec3 cameraPosition;

    // Varying
    varying vec3 vcolor;
    varying float fAlpha;
    varying mat4 worldm;
    varying vec3 vPosition;
    varying vec3 vNormal;
    varying mat4 localm;

    void main(void) {
        worldm = mat4(world0,world1,world2,world3);
        localm = world;
        vec2 CD = vec2(animation.z/2048.,1.-uv2.y);
        vcolor = texture2D(texAnim,CD).rgb;
        vec2  uvp = uv;
        float T = time.x;
        float fStart =  animation.x;
        float fEnd =  animation.y;
        float tot =  totalFrames;
        float b = fStart/tot;
        float c = fEnd/tot;
        float d =  fract(T/(c-b))*(c-b)+b;
        float f = T/(tot);
        float e = fract(f/((fEnd-fStart)/(tot)))*((fEnd - fStart)/(tot)) + b;
        //vec2 uvPos1 = vec2(uv.x, uv.y - e);
        //vec4 pos1 = texture2D(texAnim ,uvPos1);

        vec2 uvPos1 = vec2(uv.x, uv.y - e);
        vec2 uvPos2 = vec2(uv.x,  1.-uv.y + e);
        highp vec4 pos1 = texture2D(texAnim, uvPos1);
        pos1 += texture2D(texAnim, uvPos2) * .01;

        pos1-= vec4(.5,1.0,0.5,0.);
        pos1*=-100.;
        gl_Position = viewProjection * mat4(world0,world1,world2,world3)  *  vec4(pos1.xyz,1.0);

        worldm = world;
        vPosition = position;
        vNormal = normal;
        //fAlpha = animation.w
        fAlpha = clamp((abs(animation.w-30.0)-29.0)/1.0,0.,1.);

    }`;
    BABYLON.Effect.ShadersStore.customFragmentShader = `precision highp float;
    precision mediump float;
    // Varying
    varying vec3 vcolor;
    varying float fAlpha;
    varying mat4 worldm;
    varying vec3 vPosition;
    varying vec3 vNormal;
    varying mat4 localm;
    // Refs
    uniform sampler2D refSampler;
    void main(void) {
      vec3 vLightPosition = vec3(0.,-100.0,0.);
    // World values
    vec3 vPositionW = vec3(worldm * vec4(vPosition, 1.0));
    vec3 vNormalW = normalize(vec3(worldm * vec4(vNormal, 0.0)));
    // Light
    vec3 lightVectorW = normalize(vLightPosition - vPositionW);
    float light = max(0., dot(vNormalW, lightVectorW));
    //disolve
    float boxX = abs(fract(gl_FragCoord.x/10.)-.5)*2.;
    float boxY = abs(fract(gl_FragCoord.y/10.)-.5)*2.;
    float box = boxX * boxY + 1. - fAlpha*2.;
    gl_FragColor = vec4(vcolor*.7 + light*.15  , 1.0);
  //gl_FragColor = vec4(fAlpha,fAlpha,fAlpha,1.0);
    if(box < .1) discard;
    }`;
    var shader = new BABYLON.ShaderMaterial("shaderGradient", scene, "custom", {
      attributes: ["animation", "uv2", "uv", "color", "normal", "time"],
      defines: ["#define INSTANCES"],
      uniforms: ["totalFrames", "texAnim", "worldViewProjection", "viewProjection", "cameraPosition", "world", "worldView"]
    });

    shader.setTexture("texAnim", animTexture);
    shader.setFloat("totalFrames", totalFrames);
    mesh.material = shader;
    mesh.material.backFaceCulling = true;
    mesh.material.needDepthPrePass = true;
    resolve(true);
  })
}

function startBotsClone(mesh, animations, botName, botsArr, isWalk) {
  return new Promise(resolve => {
    let length = 1;
    let i = 0;
    if (i == length) {
      resolve(true);
    }
    botStartWalk(mesh);
    createInstance();

    function createInstance() {
      let instance = mesh.createInstance(botName + "_instance");
      setAnimation(instance, 0, randomIntFromInterval(1, 15), true);
      if (isWalk) {
        botStartWalk(instance);
      } else {
        //  botInitStatic(instance);
      }
      if (i < length) {
        i++;
        setTimeout(() => {
          createInstance();
        }, 150);
      }
      resolve(true);
    }
  })
}

function startPointsWalk(countPoints) {
  if (local.botsIsReady) {
    local.botsPointsWalk = [];
    for (var i = 0; i < countPoints; i++) {
      local.botsPointsWalk.push({
        pos: new BABYLON.Vector3.Zero(),
        end: "point where we go"
      });
      pointsWalker(local.botsPointsWalk[i]);
    }
  } else {
    setTimeout(() => {
      startPointsWalk(countPoints)
    }, 400);
  }
}

function pointsWalker(wPoint) {
  wPoint.point = 0;
  //let CB = BABYLON.MeshBuilder.CreateBox("CB", {height: 1, width: 1, depth: 1});
  wPoint.pointWalkPoints = Walkpoints.Walkpoints[randomIntFromInterval(0, Walkpoints.Walkpoints.length - 1)];
  wPoint.pos = new BABYLON.Vector3(wPoint.pointWalkPoints[0][0], wPoint.pointWalkPoints[0][1], wPoint.pointWalkPoints[0][2]);
  wPoint.end = wPoint.pointWalkPoints;
  wPoint.observale = scene.onBeforeRenderObservable.add(function() {
    if (local.fps > 15) {
      let curPos = new BABYLON.Vector3(wPoint.pos.x, wPoint.pos.y, wPoint.pos.z);
      let endPos = new BABYLON.Vector3(wPoint.end[wPoint.point][0], wPoint.end[wPoint.point][1], wPoint.end[wPoint.point][2]);
      if (endPos.subtract(curPos).length() < 1.5) {
        if (wPoint.point == wPoint.pointWalkPoints.length - 3) {
          wPoint.pointWalkPoints = Walkpoints.Walkpoints[
            wPoint.pointWalkPoints[wPoint.pointWalkPoints.length - 2][randomIntFromInterval(0, wPoint.pointWalkPoints[wPoint.pointWalkPoints.length - 2].length - 1)]
          ]
          wPoint.point = 0;
        } else {
          wPoint.point++;
        }
        wPoint.end = wPoint.pointWalkPoints;
        return;
      }
      let dir = endPos.subtract(curPos);
      let det = endPos.subtract(curPos).length() / local.WSpeed;
      wPoint.pos = new BABYLON.Vector3(wPoint.pos.x + (dir.x / det), wPoint.pos.y + (dir.y / det), wPoint.pos.z + (dir.z / det));
      //    CB.position.set(wPoint.pos.x,wPoint.pos.y,wPoint.pos.z);
    }
  })
}

function checkBotsPos() {
  if (local.hero != null && local.botsIsReady) {
    let timer = 0;
    local.observals.checkBotsPos = scene.onBeforeRenderObservable.add(function() {
      if (timer > (local.fps * 4)) {
        updateBots();
        timer = 0;
      } else {
        timer++;
      }
    });
  } else {
    setTimeout(() => {
      checkBotsPos()
    }, 700);
  }
}

function updateBots() {
  local.wTruePoints = [];
  local.wTrueBots = [];
  local.sTruePoints = [];
  local.BOTS.forEach((item) => {
    if (item.position.subtract(local.hero.position).length() > 65) {
      local.wTrueBots.push(item);
    }
  });

  let Arrs = new Promise(function(resolve, reject) {
    local.botsPointsWalk.forEach((item, i) => {
      if (item.pos.subtract(local.hero.position).length() > 61 && item.pos.subtract(local.hero.position).length() < 70) {
        local.wTruePoints.push(item);
      }
    });
    local.sBotsPoints.forEach((item, i) => {
      if (item.bot == "" && new BABYLON.Vector3(item.pos[0], item.pos[1], item.pos[2]).subtract(local.hero.position).length() > 61 && new BABYLON.Vector3(item.pos[0], item.pos[1], item.pos[2]).subtract(local.hero.position).length() < 80) {
        local.sTruePoints.push(item);
      }
      local.wTrueBots.forEach(bot => {
        if (bot.name == item.bot) {
          //  item.bot = "";
        }
      });
      if (i == local.sBotsPoints.length - 1) {
        resolve(true);
      }
    });
  });
  Arrs.then(() => {
    var j = 0;
    local.StatBOTS = 0;
    local.WalkBOTS = 0;
    zigzagReplace(local.wTruePoints, local.sTruePoints)
  })
};

function zigzagReplace(wTruePoints, sTruePoints) {
  let all = wTruePoints.length < sTruePoints.length ? wTruePoints.concat(sTruePoints) : sTruePoints.concat(wTruePoints);
  //let all = wTruePoints;
  for (var i = 0; i < all.length; i++) {
    if (i == local.wTrueBots.length) {
      break;
    }
    if (all[i].bot != null) {
      statBotsReplace(i, all[i]);
    } else {
      walkBotsReplace(i, all[i]);
    }
  }

}

function statBotsReplace(j, elem) {
  if (local.wTrueBots[j].observale != null) {
    scene.onBeforeRenderObservable.remove(local.wTrueBots[j].observale);
  }
  local.wTrueBots[j].position.set(elem.pos[0], elem.pos[1], elem.pos[2]);
  setTimeout(() => {
    local.wTrueBots[j].rotation = new BABYLON.Vector3(local.wTrueBots[j].rotation.x, elem.rot[0], local.wTrueBots[j].rotation.z); //*-1 -  Math.PI/3.5
  }, 150);
  setAnimation(local.wTrueBots[j]._children[0], randomIntFromInterval(1, local.botsdRanges.length - 2), randomIntFromInterval(1, 15), false);
  local.StatBOTS += 1;
  elem.bot = local.wTrueBots[j].name;
}

function walkBotsReplace(j, elem) {
  if (local.wTrueBots[j].observale != null) {
    scene.onBeforeRenderObservable.remove(local.wTrueBots[j].observale);
  }
  local.wTrueBots[j].botsWalkPoints = elem.pointWalkPoints;
  local.wTrueBots[j].point = elem.point;
  local.wTrueBots[j].position.set(elem.pos.x, elem.pos.y, elem.pos.z);
  setAnimation(local.wTrueBots[j]._children[0], 0, randomIntFromInterval(1, 15), true);
  inintBotWalk(local.wTrueBots[j]);
  local.WalkBOTS++;
}

function botStartWalk(bot) {
  let CB = BABYLON.MeshBuilder.CreateBox("BOT_" + bot.name, {
    height: 1.5,
    width: 1,
    depth: 1
  }, scene);;
  CB.checkCollisions = true;
  CB.showBoundingBox = true;
  CB.point = 0;
  CB.visibility = 0;
  local.BOTS.push(CB);
  CB.botsWalkPoints = Walkpoints.Walkpoints[randomIntFromInterval(0, Walkpoints.Walkpoints.length - 1)];
  let speedratio = 1;
  bot.parent = CB;
  bot.position.set(bot.position.x, bot.position.y + 1, bot.position.z);
  bot.rotation = new BABYLON.Vector3(0, Math.PI, Math.PI);
  CB.position.set(CB.botsWalkPoints[0][0], CB.botsWalkPoints[0][1], CB.botsWalkPoints[0][2]);
  inintBotWalk(CB);
}
//let botBox = BABYLON.MeshBuilder.CreateBox("CB", {height: 1, width: 1, depth: 1});
function inintBotWalk(CB) {
  local.deltaFw = Math.PI / 4;
  CB.observale = scene.onBeforeRenderObservable.add(function() {
    if (local.fps > 15) {
      let curPos = new BABYLON.Vector3(CB.position.x, CB.position.y, CB.position.z);
      let endPos = new BABYLON.Vector3(CB.botsWalkPoints[CB.point][0], CB.botsWalkPoints[CB.point][1], CB.botsWalkPoints[CB.point][2]);
      let dirPos = curPos.subtract(endPos).normalize();
      let forward = CB.forward;
      let forwardM = new BABYLON.Vector3(forward.x, dirPos.y, forward.z).normalize();
      if (curPos.subtract(endPos).length() < 1.5) {
        if (CB.point == CB.botsWalkPoints.length - 3) {
          CB.botsWalkPoints = Walkpoints.Walkpoints[
            CB.botsWalkPoints[CB.botsWalkPoints.length - 2][randomIntFromInterval(0, CB.botsWalkPoints[CB.botsWalkPoints.length - 2].length - 1)]
          ]
          CB.point = 0;
        } else {
          CB.point++;
        }
        return;
      }
      CB.position.y = CB.position.y - forwardM.y * local.WSpeed;
      CB.position.addInPlace(CB.forward.scaleInPlace(local.WSpeed));
      let trueFw = Math.abs(BABYLON.Vector3.GetAngleBetweenVectors(dirPos, forward, forward.add(dirPos)));
      let rotDirY, rotdelta, HpredPos, BpredPos, dirPred, BdeltaP, HdeltaP;
      HdeltaP = local.curSpeed * local.fps / 4;
      HpredPos = local.hero.position.add(local.hero.forward.multiplyByFloats(HdeltaP, HdeltaP, HdeltaP));
      BdeltaP = local.WSpeed * local.fps / 4;
      BpredPos = CB.position.add(forward.multiplyByFloats(BdeltaP, BdeltaP, BdeltaP));
      dirPred = HpredPos.subtract(BpredPos).normalize();

      if (Math.abs(BpredPos.subtract(HpredPos).length()) > local.distR) {
        rotDirY = BABYLON.Vector3.Cross(forward, dirPos).y < 0 ? 1 : -1;
      } else {
        //local.nearBots.push
        //botBox.position = BpredPos;
        trueFw = Math.abs(BABYLON.Vector3.GetAngleBetweenVectors(dirPred, forward, forward.add(dirPred)));
        //  if(Math.abs(BABYLON.Vector3.GetAngleBetweenVectors(dirPred,forward,forward.add(dirPred))) < Math.PI/1.3){
        rotDirY = BABYLON.Vector3.Cross(dirPred, forward).y < 0 ? -1 : 1;
      }
      if (trueFw < 3.12) {
        CB.rotation = new BABYLON.Vector3(CB.rotation.x, CB.rotation.y + (.03 * rotDirY), CB.rotation.z);
      }
    }
  });
}
export {
  init3d
};
