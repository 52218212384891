<template>
    <div class="interface-mobile">
        <div class="interface-mobile--hamburger">
            <div class="interface-mobile--menu">
                <router-link to="catalog" class="mobile--menu mobile-catalog">
                    <span>{{$t('sceneInterface.catalog')}}</span>
                </router-link>
                <div @click="rightBarActive('help')"
                    class="mobile--menu mobile-help">
                    <span>{{$t('sceneInterface.help')}}</span>
                </div>
                <a href="https://mrexpo.ru/broadcast/" target="_blank"
                    class="mobile--menu mobile-stream">
                    <span>{{$t('sceneInterface.stream')}}</span>
                </a>
                <div @click="rightBarActive('info')"
                    class="mobile--menu mobile-info">
                    <span>{{$t('sceneInterface.info')}}</span>
                </div>
                <div @click="rightBarActive('chat')"
                    class="mobile--menu mobile-chat">
                    <span>{{$t('sceneInterface.chat')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "interfaceMobile",
    mounted() {
        window.localIntMob ={};
        localIntMob.click = new Event("click", {bubbles : true, cancelable : true})
    },
    methods: {
        rightBarActive(name){
        if(document.querySelector(".star-anim.anim-to") != null){
            document.querySelector(".star-anim.anim-to").classList.remove("anim-to");
            document.querySelector(".star-anim_blue.anim-to").classList.remove("anim-to");
        }
        this.checkOpens();
        if(name == "chat"){
            document.querySelector(".sc-launcher").dispatchEvent(localIntMob.click);
            setTimeout(()=>{
                    document.querySelector(".sc-header--close-button").addEventListener("click",()=>{this.closeBlock()})
            },50)
        }else{
            document.querySelector(".js-b-"+name).classList.add("active");
        }
        document.querySelector(".js-right-item--"+name).classList.add("active");
        },
        checkOpens() {
            if(document.querySelector(".right-item.active") != null){
                document.querySelector(".right-item.active").classList.remove("active");
            }
            if(document.querySelector(".b-block.active") != null){
                document.querySelector(".b-block.active").classList.remove("active");
            }
            if(document.querySelector(".nav-icon3.open") != null){
                document.querySelector(".nav-icon3.open").classList.remove("open");
                document.querySelector(".interface-mobile.active").classList.remove("active");
            }
            if(document.querySelector(".sc-launcher.opened") !=null){
                document.querySelector(".sc-header--close-button").dispatchEvent(localIntMob.click);
            }
        },
    }
}
</script>

<style scoped>

</style>

