let Allstars = [
{isIcon:false,name:"star_1",pos:[115.248,1.42,42.11]},
{isIcon:false,name:"star_2",pos:[95.547,1.394,39.837]},
{isIcon:false,name:"star_3",pos:[75.547,1.347,39.837]},
{isIcon:false,name:"star_4",pos:[55.548,1.233,39.837]},
{isIcon:false,name:"star_5",pos:[37.692,1.345,47.302]},
{isIcon:false,name:"star_6",pos:[19.031,1.164,53.009]},
{isIcon:false,name:"star_7",pos:[-0.905,1.179,51.941]},
{isIcon:false,name:"star_8",pos:[94.381,1.412,38.221]},
{isIcon:false,name:"star_9",pos:[97.79,2.088,-15.783]},
{isIcon:false,name:"star_10",pos:[137.696,1.377,58.793]},
{isIcon:false,name:"star_11",pos:[140.554,0.207,78.301]},
{isIcon:false,name:"star_12",pos:[140.593,-0.899,98.27]},
{isIcon:false,name:"star_13",pos:[136.869,-1.186,117.6]},
{isIcon:false,name:"star_14",pos:[121.926,-1.198,130.413]},
{isIcon:false,name:"star_15",pos:[131.067,-0.802,89.735]},
{isIcon:false,name:"star_16",pos:[123.431,0.294,80.063]},
{isIcon:false,name:"star_17",pos:[130.871,0.409,70.942]},
{isIcon:false,name:"star_18",pos:[114.608,1.286,68.087]},
{isIcon:false,name:"star_19",pos:[105.723,2.564,54.416]},
{isIcon:false,name:"star_20",pos:[92.174,3.571,45.21]},
{isIcon:false,name:"star_21",pos:[110.709,-0.946,117.944]},
{isIcon:false,name:"star_22",pos:[95.616,-4.398,111.13]},
{isIcon:false,name:"star_23",pos:[96.321,-4.398,92.76]},
{isIcon:false,name:"star_24",pos:[79.278,-4.4,86.646]},
{isIcon:false,name:"star_25",pos:[101.342,-1.225,134.435]},
{isIcon:false,name:"star_26",pos:[82.755,-4.397,132.296]},
{isIcon:false,name:"star_27",pos:[104.457,-1.485,146.799]},
{isIcon:false,name:"star_28",pos:[88.187,-3.156,157.829]},
{isIcon:false,name:"star_29",pos:[68.647,-4.367,161.633]},
{isIcon:false,name:"star_30",pos:[62.234,-4.396,140.882]},
{isIcon:false,name:"star_31",pos:[46.182,-4.396,151.086]},
{isIcon:false,name:"star_32",pos:[27.268,-4.396,114.718]},
{isIcon:false,name:"star_33",pos:[7.895,-4.396,117.134]},
{isIcon:false,name:"star_34",pos:[-7.264,-4.396,105.284]},
{isIcon:false,name:"star_35",pos:[-13.143,-4.383,144.052]},
{isIcon:false,name:"star_36",pos:[-28.628,-4.383,131.414]},
{isIcon:false,name:"star_37",pos:[-41.088,-4.38,115.868]},
{isIcon:false,name:"star_38",pos:[-54.916,-3.498,101.958]},
{isIcon:false,name:"star_39",pos:[-66.532,0.531,87.814]},
{isIcon:false,name:"star_40",pos:[-23.133,1.158,53.004]},
{isIcon:false,name:"star_41",pos:[-42.037,1.387,58.05]},
{isIcon:false,name:"star_42",pos:[-47.287,3.611,45.756]},
{isIcon:false,name:"star_43",pos:[-68.045,1.209,-48.178]},
{isIcon:false,name:"star_44",pos:[-82.308,1.209,-34.299]},
{isIcon:false,name:"star_45",pos:[-100.39,1.209,-26.069]},
{isIcon:false,name:"star_46",pos:[-97.233,1.209,-23.048]},
{isIcon:false,name:"star_47",pos:[-105.867,1.209,-7.002]},
{isIcon:false,name:"star_48",pos:[-109.364,1.209,12.591]},
{isIcon:false,name:"star_49",pos:[-89.72,1.209,-52.106]},
{isIcon:false,name:"star_50",pos:[-108.035,1.209,-44.269]},
{isIcon:false,name:"star_51",pos:[-44.17,1.939,-40.937]},
{isIcon:false,name:"star_52",pos:[-60.109,7.581,-51.326]},
{isIcon:false,name:"star_53",pos:[34.395,1.211,-43.875]},
{isIcon:false,name:"star_54",pos:[54.254,1.211,-41.699]},
{isIcon:false,name:"star_55",pos:[24.69,1.211,-67.086]},
{isIcon:false,name:"star_56",pos:[11.168,1.211,-71.242]},
{isIcon:false,name:"star_57",pos:[22.972,1.21,-31.023]},
{isIcon:false,name:"star_58",pos:[9.895,1.21,-45.909]},
{isIcon:false,name:"star_59",pos:[-8.721,1.21,-51.943]},
{isIcon:false,name:"star_60",pos:[1.128,1.211,-90.178]},
{isIcon:false,name:"star_61",pos:[-10.811,1.211,-74.625]},
{isIcon:false,name:"star_62",pos:[37.05,1.211,-101.494]},
{isIcon:false,name:"star_63",pos:[18.34,1.211,-104.189]},
{isIcon:false,name:"star_64",pos:[-9.655,1.211,-111.914]},
{isIcon:false,name:"star_65",pos:[-28.715,1.211,-110.544]},
{isIcon:false,name:"star_66",pos:[-41.349,1.211,-93.334]},
{isIcon:false,name:"star_67",pos:[-60.176,1.211,-96.426]},
{isIcon:false,name:"star_68",pos:[-21.779,1.21,23.802]},
{isIcon:false,name:"star_69",pos:[-38.884,1.21,13.735]},
{isIcon:false,name:"star_70",pos:[-47.907,1.21,-3.956]},
{isIcon:false,name:"star_71",pos:[-47.241,1.21,-23.737]},
{isIcon:false,name:"star_72",pos:[-36.658,1.21,-40.445]},
{isIcon:false,name:"star_73",pos:[-11.221,1.133,60.699]},
{isIcon:false,name:"star_74",pos:[-20.753,1.283,77.529]},
{isIcon:false,name:"star_75",pos:[-10.61,1.283,88.339]},
{isIcon:false,name:"star_76",pos:[8.811,1.283,83.807]},
{isIcon:false,name:"star_77",pos:[28.739,1.283,84.783]},
{isIcon:false,name:"star_78",pos:[48.253,1.283,89.142]},
{isIcon:false,name:"star_79",pos:[68.109,1.283,91.179]},
{isIcon:false,name:"star_80",pos:[87.786,1.283,88.081]},
{isIcon:false,name:"star_81",pos:[105.267,1.283,78.59]},
{isIcon:false,name:"star_82",pos:[118.697,1.377,63.856]},
{isIcon:false,name:"star_83",pos:[-13.473,1.276,88.818]},
{isIcon:false,name:"star_84",pos:[-11.9,1.267,108.497]},
{isIcon:false,name:"star_85",pos:[1.332,1.267,123.083]},
{isIcon:false,name:"star_86",pos:[18.817,1.267,132.731]},
{isIcon:false,name:"star_87",pos:[37.667,0.468,139.069]},
{isIcon:false,name:"star_88",pos:[56.489,-4.162,136.15]},
{isIcon:false,name:"star_89",pos:[-77.196,1.988,-91.665]},
{isIcon:false,name:"star_90",pos:[-64.069,1.988,-106.532]},
{isIcon:false,name:"star_91",pos:[-47.409,1.988,-117.547]},
{isIcon:false,name:"star_92",pos:[-29.054,1.988,-125.439]},
{isIcon:false,name:"star_93",pos:[-9.817,1.988,-130.853]},
{isIcon:false,name:"star_94",pos:[10.041,1.988,-132.67]},
{isIcon:false,name:"star_95",pos:[29.103,1.988,-127.081]},
{isIcon:false,name:"star_96",pos:[44.812,1.994,-114.903]},
{isIcon:false,name:"star_97",pos:[53.716,1.988,-97.112]},
{isIcon:false,name:"star_98",pos:[67.397,1.988,-84.322]},
{isIcon:false,name:"star_99",pos:[84.078,1.988,-74.951]},
{isIcon:false,name:"star_100",pos:[91.175,1.988,-57.106]},
{isIcon:false,name:"star_101",pos:[93.18,2.082,-37.636]},
{isIcon:false,name:"star_102",pos:[88.817,2.088,-18.187]},
{isIcon:false,name:"star_103",pos:[76.864,1.988,-2.567]}
]
let AllBtns =[{name:"SM_4",pos:[43.725,1.862,-63.267]},
{name:"SM_349",pos:[44.963,1.29,-64.059]},
{name:"SM_350",pos:[47.625,1.29,-57.634]},
{name:"SM_65",pos:[42.089,1.975,-58.83]},
{name:"SM_67",pos:[42.748,0.729,-55.099]},
{name:"SM_245",pos:[38.949,0.763,-56.582]},
{name:"SM_209",pos:[38.501,1.158,-53.625]},
{name:"SM_5",pos:[53.048,0.763,-54.982]},
{name:"SM_92",pos:[53.894,0.763,-54.734]},
{name:"SM_172",pos:[57.439,1.088,-51.417]},
{name:"SM_247",pos:[58.686,0.763,-53.164]},
{name:"SM_166",pos:[60.106,1.226,-55.231]},
{name:"SM_244",pos:[63.851,0.922,-55.242]},
{name:"SM_239",pos:[63.008,1.621,-52.154]},
{name:"SM_235",pos:[60.937,1.217,-49.845]},
{name:"SM_203",pos:[28.108,2.172,-61.483]},
{name:"SM_202",pos:[28.423,1.414,-75.126]},
{name:"SM_341",pos:[34.882,1.022,-79.247]},
{name:"SM_412",pos:[17.966,2.364,-84.244]},
{name:"SM_306",pos:[21.344,2.364,-91.373]},
{name:"SM_415",pos:[21.868,1.913,-98.598]},
{name:"SM_305",pos:[18.154,0.565,-94.936]},
{name:"SM_289",pos:[-9.329,1.935,-92.947]},
{name:"SM_308",pos:[-7.969,1.258,-98.79]},
{name:"SM_413",pos:[-12.918,1.599,-102.184]},
{name:"SM_211",pos:[-27.589,2.749,-93.7]},
{name:"SM_153",pos:[-24.887,2.749,-86.258]},
{name:"SM_406",pos:[-32.112,3.084,-76.837]},
{name:"SM_210",pos:[-44.309,1.558,-79.723]},
{name:"SM_309",pos:[-44.337,1.121,-85.878]},
{name:"SM_307",pos:[-48.647,1.612,-85.241]},
{name:"SM_292",pos:[-47.805,1.113,-89.697]},
{name:"SM_171_0002",pos:[-62.909,1.113,-88.616]},
{name:"SM_295",pos:[-62.442,1.113,-84.08]},
{name:"SM_172__002",pos:[-63.787,1.113,-86.228]},
{name:"SM_334",pos:[-99.719,1.243,22.665]},
{name:"SM_64",pos:[-63.031,1.113,-78.529]},
{name:"SM_333",pos:[-62.992,1.399,-77.169]},
{name:"SM_337",pos:[-61.945,1.113,-76.312]},
{name:"SM_208",pos:[-60.789,1.113,-78.599]},
{name:"SM_216",pos:[-61.593,1.508,-72.739]},
{name:"SM_32",pos:[-23.029,2.449,-57.867]},
{name:"SM_598",pos:[-21.04,1.301,-57.543]},
{name:"SM_385",pos:[-17.194,1.451,-46.579]},
{name:"SM_56",pos:[-19.395,1.301,-41.657]},
{name:"SM_328",pos:[-19.397,1.835,-39.087]},
{name:"SM_57",pos:[-25.257,1.301,-43.287]},
{name:"SM_324",pos:[-115.303,1.288,-21.276]},
{name:"SM_163",pos:[-4.56,1.119,-47.101]},
{name:"SM_911",pos:[30.082,1.79,-30.085]},
{name:"SM_9110",pos:[27.976,0.931,-34.643]},
{name:"SM_595",pos:[5.631,1.301,-41.12]},
{name:"SM_286",pos:[-35.467,1.301,-33.9]},
{name:"SM_329",pos:[4.894,1.547,-36.967]},
{name:"SM_594",pos:[5.648,1.056,-31.599]},
{name:"SM_914",pos:[15.825,1.144,-30.865]},
{name:"SM_185",pos:[15.475,1.301,-28.374]},
{name:"SM_281",pos:[16.57,1.041,-24.333]},
{name:"SM_596",pos:[18.95,0.83,-24.753]},
{name:"SM_597",pos:[19.883,0.86,-24.177]},
{name:"SM_351",pos:[19.653,1.202,-20.359]},
{name:"SM_593",pos:[18.255,0.802,-20.051]},
{name:"SM_165",pos:[24.944,1.37,-18.319]},
{name:"SM_23",pos:[20.185,1.527,-44.655]},
{name:"SM_205",pos:[18.733,1.37,-47.408]},
{name:"SM_250",pos:[16.973,1.457,-46.439]},
{name:"SM_204",pos:[21.079,1.499,-46.91]},
{name:"SM_152",pos:[14.751,1.37,-51.089]},
{name:"SM_206",pos:[18.617,1.37,-49.827]},
{name:"SM_240",pos:[-3.867,1.6,-71.118]},
{name:"SM_246",pos:[1.528,1.457,-73.239]},
{name:"SM_234",pos:[23.828,1.466,-2.886]},
{name:"SM_902",pos:[65.775,2.01,-24.881]},
{name:"SM_128",pos:[61.689,1.646,-31.517]},
{name:"SM_148___2",pos:[-8.355,0.766,-68.681]},
{name:"SM_176",pos:[62.829,0.626,-37.228]},
{name:"SM_580",pos:[64.948,1.315,-35.563]},
{name:"SM_214",pos:[61.508,4.817,-3.027]},
{name:"SM_44",pos:[77.036,3.05,30.134]},
{name:"SM_146",pos:[99.655,1.932,29.744]},
{name:"SM_160",pos:[88.981,2.087,2.434]},
{name:"SM_45",pos:[77.837,2.161,25.164]},
{name:"SM_37",pos:[82.275,1.393,26.056]},
{name:"SM_150",pos:[105.529,1.715,30.854]},
{name:"SM_201",pos:[65.947,1.39,25.362]},
{name:"SM_49",pos:[89.039,0.68,34.868]},
{name:"SM_147",pos:[103.683,1.141,27.131]},
{name:"SM_41",pos:[72.728,1.393,30.507]},
{name:"SM_47",pos:[77.194,1.393,34.05]},
{name:"SM_199",pos:[61.932,1.391,35.119]},
{name:"SM_149",pos:[102.66,1.393,34.529]},
{name:"SM_198",pos:[62.032,1.936,26.371]},
{name:"SM_200",pos:[66.769,1.391,29.215]},
{name:"SM_43",pos:[80.56,1.393,32.109]},
{name:"SM_38",pos:[82.904,0.836,36.329]},
{name:"SM_50",pos:[88.815,2.544,30.959]},
{name:"SM_197",pos:[61.837,1.09,30.529]},
{name:"SM_48",pos:[72.443,0.735,36.282]},
{name:"SM_362",pos:[90.787,3.908,53.213]},
{name:"SM_431",pos:[91.551,3.59,53.919]},
{name:"SM_186",pos:[92.762,3.812,55.081]},
{name:"SM_155",pos:[63.131,-1.665,58.479]},
{name:"SM_177",pos:[50.575,-2.504,62.609]},
{name:"SM_53",pos:[30.731,-3.6,72.578]},
{name:"SM_84",pos:[-7.925,1,57.616]},
{name:"SM_108",pos:[-6.062,1,56.756]},
{name:"SM_151",pos:[-5.554,0.758,45.724]},
{name:"SM_106",pos:[-6.548,1,44.463]},
{name:"SM_264",pos:[-15.858,1.881,52.27]},
{name:"SM_151",pos:[-19.48,0.803,47.375]},
{name:"SM_326",pos:[-18.902,1.535,46.115]},
{name:"SM_1126",pos:[-20.082,1.448,48.888]},
{name:"SM_233",pos:[-23.317,1.071,55.359]},
{name:"SM_224",pos:[-24.946,0.894,55.168]},
{name:"SM_274",pos:[-26.501,1.535,54.793]},
{name:"SM_316",pos:[-38.879,1.111,54.615]},
{name:"SM_243",pos:[-39.954,0.915,55.301]},
{name:"SM_273",pos:[-41.255,1.535,56.185]},
{name:"SM_182",pos:[-43.16,1.111,65.048]},
{name:"SM_242",pos:[-42.249,1.461,66.458]},
{name:"SM_1128",pos:[-52.232,1.461,60.386]},
{name:"SM_84",pos:[-54.457,1.055,60.535]},
{name:"SM_267",pos:[-50.808,4.13,75.946]},
{name:"SM_86",pos:[-64.851,1.507,75.231]},
{name:"SM_113",pos:[-65.242,1.867,73.149]},
{name:"SM_106",pos:[-73.074,3.47,61.276]},
{name:"SM_233",pos:[-73.921,3.998,59.595]},
{name:"SM_106",pos:[-78.859,5.115,57.125]},
{name:"SM_326",pos:[-82.768,5.535,60.22]},
{name:"SM_106",pos:[-86.024,5.465,61.234]},
{name:"SM_106",pos:[-93.998,8.292,56.561]},
{name:"SM_106",pos:[-103.107,10.277,61.621]},
{name:"SM_220",pos:[92.07,2.492,-58.392]},
{name:"SM_106",pos:[-108.724,11.566,55.013]},
{name:"SM_296",pos:[32.987,-4.121,117.822]},
{name:"SM_384",pos:[32.775,-4.694,122.719]},
{name:"SM_376",pos:[-82.022,1.114,-46.152]},
{name:"SM_383",pos:[-82.376,0.926,-47.904]},
{name:"SM_297",pos:[31.349,-4.161,129.882]},
{name:"SM_428",pos:[18.944,-3.6,140.669]},
{name:"SM_429",pos:[31.041,-3.871,149.405]},
{name:"SM_117",pos:[26.208,-3.961,156.61]},
{name:"SM_401",pos:[7.646,-4.6,149.235]},
{name:"SM_40",pos:[9.088,-4.443,143.526]},
{name:"SM_195",pos:[7.866,-4.443,140.377]},
{name:"SM_194",pos:[4.537,-4.443,141.044]},
{name:"SM_148",pos:[5.205,-4.443,137.085]},
{name:"SM_237",pos:[5.021,-4.375,132.57]},
{name:"SM_359",pos:[-12.553,-4.122,141.237]},
{name:"SM_266",pos:[-2.192,-3.6,150.679]},
{name:"SM_592",pos:[-9.387,-4.623,152.504]},
{name:"SM_164",pos:[-61.908,1.339,17.332]},
{name:"SM_116",pos:[13.026,-3.6,152.769]},
{name:"SM_226",pos:[38.109,-4.306,162.123]},
{name:"SM_111",pos:[39.782,-4.026,162.066]},
{name:"SM_317",pos:[37.829,-4.052,157.371]},
{name:"SM_122",pos:[51.312,-3.963,156.811]},
{name:"SM_213",pos:[47.2,-3.6,145.132]},
{name:"SM_60",pos:[72.886,-3.6,135.901]},
{name:"SM_159",pos:[54.283,-4.062,119.108]},
{name:"SM_353",pos:[52.205,-4.813,129.645]},
{name:"SM_241",pos:[66.145,-4.274,121.732]},
{name:"SM_542",pos:[59.94,-4.107,115.071]},
{name:"SM_238",pos:[58.812,-4.044,111.081]},
{name:"SM_174",pos:[55.295,-4.598,109.086]},
{name:"SM_101",pos:[85.171,-4.585,129.323]},
{name:"SM_51",pos:[83.128,-4.044,124.919]},
{name:"SM_275",pos:[86.364,-4.306,117.783]},
{name:"SM_904",pos:[83.635,-4.226,109.958]},
{name:"SM_151",pos:[69.767,-4.491,104.205]},
{name:"SM_106",pos:[68.897,-4.344,105.638]},
{name:"SM_106",pos:[81.779,-4.248,99.34]},
{name:"SM_151",pos:[83.22,-4.69,100.002]},
{name:"SM_114",pos:[118.363,-0.537,86.333]},
{name:"SM_58",pos:[118.116,-1.106,103.645]},
{name:"SM_110",pos:[117.04,-1.106,104.512]},
{name:"SM_58",pos:[117.705,-1.106,105.677]},
{name:"SM_217",pos:[124.441,-0.714,105.993]},
{name:"SM_179",pos:[119.811,-0.315,112.366]},
{name:"SM_98",pos:[119.925,-1.3,119.469]},
{name:"SM_376____2",pos:[121.45,-1.077,118.775]},
{name:"SM_288",pos:[114.042,-1.609,127.36]},
{name:"SM_105",pos:[115.635,-1.312,128.067]},
{name:"SM_145",pos:[129.599,-0.909,123.415]},
{name:"SM_157",pos:[142.671,-1.144,104.886]},
{name:"SM_105",pos:[118.304,1.742,50.464]},
{name:"SM_167",pos:[118.65,1.742,51.74]},
{name:"SM_426",pos:[119.435,1.138,52.967]},
{name:"SM_154",pos:[128.774,2.017,46.997]},
{name:"SM_183",pos:[127.171,1.483,35.614]},
{name:"SM_228",pos:[128.524,1.124,35.799]},
{name:"SM_167",pos:[130.067,1.483,35.899]},
{name:"SM_183",pos:[140.672,1.427,44.872]},
{name:"SM_228",pos:[140.526,1.205,46.338]},
{name:"SM_167",pos:[140.467,1.427,47.961]},
{name:"SM_427",pos:[58.912,-4.168,124.769]},
{name:"SM_414",pos:[70.783,-4.424,133.597]},
{name:"SM_905",pos:[-35.84,-2.37,103.156]},
{name:"SM_362",pos:[-43.336,1.723,79.841]},
{name:"SM_431",pos:[-44.411,1.151,80.384]},
{name:"SM_186",pos:[-45.366,1.461,81.799]},
{name:"SM_169",pos:[-19.013,1.535,35.413]},
{name:"SM_170",pos:[-17.308,1.535,37.057]},
{name:"SM_1127",pos:[-16.335,1.535,38.063]},
{name:"SM_106",pos:[-23.505,3.399,35.17]},
{name:"SM_151",pos:[-24.524,3.399,36.528]},
{name:"SM_123",pos:[-28.085,3.726,31.435]},
{name:"SM_106",pos:[-32.156,3.402,27.271]},
{name:"SM_151",pos:[-30.667,3.285,27.006]},
{name:"SM_581",pos:[-36.904,1.108,24.702]},
{name:"SM_402",pos:[-41.694,1.125,23.103]},
{name:"SM_400",pos:[-43.725,2.928,30.891]},
{name:"SM_86",pos:[-44.9,1.259,17.617]},
{name:"SM_371___2",pos:[-23.821,1.405,17.789]},
{name:"SM_151",pos:[-47.491,0.941,7.323]},
{name:"SM_168",pos:[-60.468,1.044,16.143]},
{name:"SM_901",pos:[-6.475,1.535,38.105]},
{name:"SM_161",pos:[-9.414,1.535,27.954]},
{name:"SM_917",pos:[112.643,-1.192,133.346]},
{name:"SM_380",pos:[12.619,1.121,-14.552]},
{name:"SM_377",pos:[13.311,1.121,-14.068]},
{name:"SM_382",pos:[13.926,0.926,-13.475]},
{name:"SM_380",pos:[15.194,0.926,-15.325]},
{name:"SM_371",pos:[14.572,1.121,-15.681]},
{name:"SM_382",pos:[13.978,1.121,-16.105]},
{name:"SM_105",pos:[106.263,-1.293,143.153]},
{name:"SM_288",pos:[105.179,-1.508,141.796]},
{name:"SM_173",pos:[-77.946,4.038,42.352]},
{name:"SM_86",pos:[-78.668,3.443,37.247]},
{name:"SM_234",pos:[-79.558,4.001,36.31]},
{name:"SM_335",pos:[-84.321,4.155,35.734]},
{name:"SM_142",pos:[-81.965,5.115,30.526]},
{name:"SM_141",pos:[-76.155,3.673,31.968]},
{name:"SM_84",pos:[-68.492,3.475,34.872]},
{name:"SM_108",pos:[-69.244,4.011,32.919]},
{name:"SM_140",pos:[-69.969,4.271,29.161]},
{name:"SM_137",pos:[-76.152,3.75,26.087]},
{name:"SM_139",pos:[-85.33,4.441,25.531]},
{name:"SM_138",pos:[-79.409,4.441,22.923]},
{name:"SM_144",pos:[-87.476,2.936,31.291]},
{name:"SM_219",pos:[-69.704,1.283,7.437]},
{name:"SM_107",pos:[-66.343,1.283,5.858]},
{name:"SM_432",pos:[-73.892,1.283,8.395]},
{name:"SM_162",pos:[-74.257,1.283,9.625]},
{name:"SM_576",pos:[-89.706,1.283,7.634]},
{name:"SM_279",pos:[-97.977,1.283,17.135]},
{name:"SM_87",pos:[-100.251,1.283,16.787]},
{name:"SM_275____2",pos:[-106.584,1.148,15.865]},
{name:"SM_232",pos:[-106.126,1.041,14.241]},
{name:"SM_112",pos:[-104.599,1.283,5.165]},
{name:"SM_277",pos:[-104.386,0.852,3.105]},
{name:"SM_231",pos:[-103.832,1.283,-4.645]},
{name:"SM_276",pos:[-103.535,0.932,-6.578]},
{name:"SM_22",pos:[-106.022,1.658,25.249]},
{name:"SM_115",pos:[-100.005,1.283,27.256]},
{name:"SM_249",pos:[-117.947,1.283,14.126]},
{name:"SM_221",pos:[-115.601,1.283,8.333]},
{name:"SM_13",pos:[-117.266,1.644,0.204]},
{name:"SM_171",pos:[-118.785,1.318,-16.078]},
{name:"SM_221",pos:[-107.122,1.283,-15.871]},
{name:"SM_11",pos:[-113.197,1.283,-29.807]},
{name:"SM_215",pos:[-105.356,1.283,-35.905]},
{name:"SM_578",pos:[-98.498,1.317,-37.006]},
{name:"SM_10",pos:[-93.213,1.283,-41.485]},
{name:"SM_236",pos:[-87.146,1.283,-44.16]},
{name:"SM_573",pos:[-92.787,1.283,-56.001]},
{name:"SM_248",pos:[-98.613,1.283,-53.242]},
{name:"SM_120",pos:[-106.152,1.283,-49.232]},
{name:"SM_12",pos:[-132.951,2.418,-7.449]},
{name:"SM_454",pos:[-118.001,3.354,42.248]},
{name:"SM_9",pos:[-113.98,3.354,53.369]},
{name:"SM_121",pos:[-111.376,1.283,-47.335]},
{name:"SM_76",pos:[-115.94,1.283,-44.136]},
{name:"SM_225",pos:[-140.518,12.512,25.197]},
{name:"SM_227",pos:[-136.633,12.512,23.789]},
{name:"SM_418",pos:[-119.792,12.071,52.241]},
{name:"SM_230",pos:[-140.877,12.681,17.753]},
{name:"SM_421",pos:[-136.326,13.007,13.707]},
{name:"SM_229",pos:[-123.645,12.134,49.734]},
{name:"SM_417",pos:[-139.385,14.715,12.991]},
{name:"SM_416",pos:[-140.893,12.387,5.68]},
{name:"SM_223",pos:[-135.369,12.282,1.602]},
{name:"SM_222",pos:[-141.966,12.282,-0.885]},
{name:"SM_422",pos:[-140.262,12.578,-5.734]},
{name:"SM_445",pos:[-112.696,13.604,-44.706]},
{name:"SM_42",pos:[72.483,0.986,25.67]},
{name:"SM_348",pos:[46.552,0.773,-58.221]},
{name:"SM_161",pos:[-4.372,1.485,47.959]},
{name:"SM_233",pos:[-25.323,3.546,37.512]},
{name:"SM_351",pos:[-29.337,3.597,26.808]},
{name:"SM_167",pos:[-58.924,1.253,14.932]},
{name:"SM_232",pos:[-68.094,1.041,6.658]},
{name:"SM_162",pos:[84.827,-4.302,100.794]},
{name:"SM_279",pos:[70.737,-4.306,102.145]},
{name:"SM_61",pos:[25.475,1.107,-16.208]},
{name:"SM_61",pos:[23.456,1.107,-0.913]},
{name:"SM_900",pos:[-10.737,1.473,-38.524]},
{name:"SM_86",pos:[-111.346,12.535,-44.302]},
{name:"SM_442",pos:[-139.076,13.382,-8.524]},
{name:"SM_1103",pos:[96.649,1.95,-39.59]},
{name:"SM_87",pos:[-77.404,2.055,-84.217]},
{name:"SM_159",pos:[-80.194,2.055,-79.208]},
{name:"SM_316",pos:[-116.623,1.111,-21.945]},
{name:"SM_249",pos:[-83.733,1.283,-50.39]},
{name:"SM_84",pos:[-96.855,0.893,51.508]},
{name:"SM_001",pos:[-100.455,2.064,51.805]},
{name:"SM_002",pos:[56.979,0.763,-55.575]},
{name:"SM_003",pos:[62.756,1.166,-33.903]},
{name:"SM_004",pos:[-42.517,1.286,-72.976]},
{name:"SM_1104",pos:[99.249,1.964,-36.166]},
{name:"SM_442",pos:[-138.185,12.578,32.279]},
{name:"SM_221",pos:[-126.919,12.481,44.92]},
{name:"SM_169",pos:[89.557,1.535,11.326]},
{name:"SM_2000",pos:[84.511,3.59,56.839]}];
export {Allstars,AllBtns}
